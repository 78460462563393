import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography from '@mui/material/Typography'
import { NetworkKind } from '@rallycry/api-suite-typescript/dist/models/NetworkKind'
import { useEffect, useState } from 'react'
import { RcTrans } from '@/components/atoms/RcTrans'
import { YesNoButtonGroup } from '@/components/molecules/interactive/YesNoButtonGroup'
import { HandleForm } from '@/components/organisms/handles/HandleForm'
import { useCompetitionTeamInvitations } from '@/entity/competition-team/useCompetitionTeamInvitations'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useMyContactAccounts } from '@/entity/user/useMyContactAccounts'

export const TeamPendingInvite = () => {
  const [required1, setRequired1] = useState<NetworkKind | undefined>()
  const [required2, setRequired2] = useState<NetworkKind | undefined>()

  const { settings } = useCompetition()
  const { handles } = useMyContactAccounts()
  const { accept, decline, invitation } = useCompetitionTeamInvitations()

  const network1 = handles.find(it => it.network === settings?.requiredNetwork)
  const network2 = handles.find(
    it => it.network === settings?.required2ndNetwork
  )

  useEffect(() => {
    if (settings?.requiredNetwork && !network1) {
      setRequired1(settings?.requiredNetwork)
    }
    if (settings?.required2ndNetwork && !network2) {
      setRequired2(settings?.required2ndNetwork)
    }
  }, [settings, network1, network2])

  if (!invitation) return null

  const required1Set = !required1 || (required1 && network1)
  const required2Set = !required2 || (required2 && network2)

  return (
    <Box>
      <Grid container direction='row' spacing={3}>
        <Grid item xs>
          <Typography variant='h3'>
            <RcTrans i18nKey='competition:team.pending-invite' />
          </Typography>
          {required1 || required2 ? (
            <Typography variant='body2'>
              <RcTrans i18nKey='competition:team.handle-required' />
            </Typography>
          ) : null}
        </Grid>
        <Grid item>
          <YesNoButtonGroup
            disabled={!required1Set || !required2Set}
            onClickYes={() => accept(invitation?.id!)}
            onClickNo={() => decline(invitation?.id!)}
            variant='text'
          />
        </Grid>
        {required1 ? (
          <Grid item xs={12}>
            <HandleForm network={required1} />
          </Grid>
        ) : null}
        {required2 ? (
          <Grid item xs={12}>
            <HandleForm network={required2} />
          </Grid>
        ) : null}
      </Grid>
    </Box>
  )
}
