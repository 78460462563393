import { first } from 'lodash-es'
import { FullUserDisplay } from '@rallycry/api-suite-typescript/dist/models/FullUserDisplay'
import { CommunityResource } from '@rallycry/api-suite-typescript/dist/models/CommunityResource'
import { RcTrans } from '@/components/atoms/RcTrans'

export const SharedCommunities = ({
  communities,
  user
}: {
  communities: CommunityResource[]
  user?: FullUserDisplay
}) => {
  return communities?.length > 2 ? (
    <RcTrans
      i18nKey='shared.intersecting-community-many'
      tOptions={{
        user: user?.name,
        name1: communities?.[0]?.name,
        name2: communities?.[1]?.name,
        count: communities.length - 2
      }}
    />
  ) : communities?.length === 2 ? (
    <RcTrans
      i18nKey='shared.intersecting-community-dual'
      tOptions={{
        user: user?.name,
        name1: communities?.[0]?.name,
        name2: communities?.[1]?.name,
        count: communities.length
      }}
    />
  ) : communities?.length === 1 ? (
    <RcTrans
      i18nKey='shared.intersecting-community-single'
      tOptions={{
        user: user?.name,
        community: first(communities)?.name
      }}
    />
  ) : (
    <RcTrans
      i18nKey='shared.intersecting-community-none'
      tOptions={{
        user: user?.name
      }}
    />
  )
}
