import React, { useState } from 'react'
import { ContactParticipantsModal } from './ContactParticipantsModal'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { useCompetitionParticipantDocument } from '@/entity/competition/useCompetitionPartipantDocument'

export const ContactFreeAgentsButton = () => {
  const [active, setActive] = useState(false)
  const { participants } = useCompetitionParticipantDocument({
    request: { entryIsNull: true },
    pageSize: 1024,
    paused: !active
  })

  return active ? (
    <ContactParticipantsModal
      participants={participants?.map(it => it.id!)}
      label={<RcIconButton icon={['fal', 'envelope']} />}
      open={active}
      onClose={() => setActive(false)}
    />
  ) : (
    <RcIconButton icon={['fal', 'envelope']} onClick={() => setActive(true)} />
  )
}
