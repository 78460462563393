import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import { isEmpty } from 'lodash-es'
import { useState } from 'react'
import { useDebounce } from 'use-debounce'
import Box from '@mui/material/Box'
import { ContactFreeAgentsButton } from '../shared/contact/ContactFreeAgentsButton'
import { FreeAgentCard } from './FreeAgentCard'
import { InfiniteScrollV2 } from '@/components/atoms/InfiniteScrollV2'
import { SearchBox } from '@/components/molecules/input/SearchBox'
import { NoResults } from '@/components/molecules/text/NoResults'
import { RcTableResults } from '@/components/molecules/text/RcTableResults'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionParticipantDocument } from '@/entity/competition/useCompetitionPartipantDocument'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'

export const FreeAgentDisplay = ({ isModal }: { isModal?: boolean }) => {
  const [pinned] = useLocalStorage({
    key: 'pin-t3-nav',
    defaultValue: false
  })
  const [filter, setFilter] = useState('')
  const [debouncedFilter] = useDebounce(filter, 300)

  const { isOrgSuperAdmin } = useOrganization()
  const { isCompetitionModerator } = useCompetition()

  const canEdit = isCompetitionModerator || isOrgSuperAdmin
  const competitionParticipants = useCompetitionParticipantDocument({
    request: { q: debouncedFilter || undefined, entryIsNull: true }
  })
  const { participants, totalElements, hasMore, query } =
    competitionParticipants
  const { isValidating, size, setSize } = query

  return (
    <>
      <RcTableResults total={totalElements} align='flex-start' sx={{ pb: 2 }} />

      <SearchBox filter={filter} setFilter={setFilter}>
        {canEdit ? (
          <Stack direction='row' spacing={2} alignItems='center' width={'100%'}>
            <ContactFreeAgentsButton />
          </Stack>
        ) : null}
      </SearchBox>

      <Box minHeight={100} pt={3}>
        {!isValidating && isEmpty(participants) ? (
          <NoResults />
        ) : (
          <Grid container direction='row' spacing={3}>
            {participants?.map(it => (
              <Grid key={it.id} item xs={12} md={isModal || pinned ? 6 : 4}>
                <FreeAgentCard participantDisplay={it} key={it.id} />
              </Grid>
            ))}
          </Grid>
        )}
        <InfiniteScrollV2
          hasMore={hasMore}
          isLoading={isValidating}
          onLoad={() => setSize(size + 1)}
        />
      </Box>
    </>
  )
}
