import {
  CompetitionEntryControllerApi,
  QueryCompetitionEntriesRequest,
  ViewCompetitionEntriesIncludingIssuesRequest,
  ViewCompetitionEntriesLookingForMoreRequest
} from '@rallycry/api-suite-typescript/dist/apis/CompetitionEntryControllerApi'
import { useMemo } from 'react'
import {
  CompetitionEntryBatchUpdate,
  CompetitionEntryDocument,
  CompetitionEntryResource,
  EventKind,
  Expanded,
  Issue
} from '@rallycry/api-suite-typescript/dist/models'
import { CompetitionCheckInControllerApi } from '@rallycry/api-suite-typescript'
import { last } from 'lodash-es'
import { useCompetitionMeta } from '../competition/useCompetitionMeta'
import { EntityOptions, useQueryEntity } from '../useEntity'
import { useUserMeta } from '../user/useUserMeta'
import { useOrganization } from '../organization/useOrganization'
import { useCompetitionEvents } from '../competition/useCompetitionEvents'
import { useController } from '@/core/hooks/useSWRApi'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { ExpansionType, expand } from '@/core/expand'

export const useCompetitionTeams = (
  options?: EntityOptions<QueryCompetitionEntriesRequest>
) => {
  const competitionId = useParsedParam('competitionId')
  const { read: meta } = useCompetitionMeta()
  const { read: userMeta } = useUserMeta()
  const { ctrl } = useController(CompetitionEntryControllerApi)

  const entity = useQueryEntity({
    key: 'useCompetitionTeams',
    expand: '_links,content{representing,_issues,assignments}}',
    ...options,
    request: { competitionId, ...options?.request },
    metas: [userMeta, meta],
    read: (req, { entries, access }) =>
      ctrl({ metas: { entries, access } }).queryCompetitionEntries(req)
  })

  return { ...entity, teams: entity.flat, refresh: () => meta.mutate() }
}

export const useCompetitionTeamsIncludingIssues = (
  options?: EntityOptions<ViewCompetitionEntriesIncludingIssuesRequest> & {
    selfOnly?: boolean
  }
) => {
  const { organization } = useOrganization()
  const { ctrl } = useController(CompetitionEntryControllerApi)
  const { events } = useCompetitionEvents()
  const { ctrl: checkinCtrl } = useController(CompetitionCheckInControllerApi)

  const currentOrPreviousCheckins =
    events([EventKind.CHECKIN]).allCurrentOrPrevious?.map(it => it.id!) || []
  const mostRecentCheckin = last(currentOrPreviousCheckins)

  const entity = useQueryEntity({
    key: 'useCompetitionIncludingIssues' + options?.selfOnly ? 'selfOnly' : '',
    expand:
      'totalElements,content{_membersTotalElements,leader,competition,representing{additionalOrganizations}}',
    ...options,
    request: {
      ...options?.request,
      onlyCheckIns: mostRecentCheckin ? [mostRecentCheckin] : undefined
    },
    // allow user to requery the data frequently to get the latest issues
    dedupingInterval: 2000,
    mutable: true,
    read: req =>
      options?.selfOnly
        ? ctrl({
            expectedStatuses: [401],
            fallbackResponse: {}
          }).viewMyCompetitionEntriesIncludingIssues(req)
        : ctrl().viewCompetitionEntriesIncludingIssues(req)
  })

  const teams = useMemo(
    () =>
      entity.flat.map(it => toCompetitionEntryDocument(it, organization?.id)),
    [entity.flat, organization?.id]
  )

  const updateTeams = async (
    competitionId: number,
    entries: CompetitionEntryBatchUpdate[]
  ) => {
    await ctrl().batchUpdateCompetitionEntries({
      competitionId,
      CompetitionEntryBatchUpdateCommand: { content: entries }
    })
    await entity.query.mutate()
  }

  const checkInTeams = async (eventId: number, teamIds: number[]) => {
    await checkinCtrl().batchSaveCompetitionCheckIns({
      eventId,
      CompetitionCheckInBatchSaveCommand: {
        content: teamIds.map(id => ({ entryId: id }))
      }
    })
    await entity.query.mutate()
  }

  return { ...entity, teams, updateTeams, checkInTeams }
}

export const useCompetitionLookingFormore = (
  options?: EntityOptions<ViewCompetitionEntriesLookingForMoreRequest>
) => {
  const { ctrl } = useController(CompetitionEntryControllerApi)

  const entity = useQueryEntity({
    key: 'useCompetitionLookingFormore',
    expand:
      'totalElements,content{_membersTotalElements,leader,competition{game,settings}}',
    ...options,
    read: req => ctrl().viewCompetitionEntriesLookingForMore(req)
  })

  return { ...entity, teams: entity.flat }
}

const toCompetitionEntryDocument = (
  value: CompetitionEntryResource & { _expanded?: Expanded | undefined },
  orgId?: number
): CompetitionEntryDocument & { _issues: Issue[] } => {
  const competition = expand(value, value._expanded, ExpansionType.Competition)
  const leader = expand(value, value._expanded, ExpansionType.CompetitionLeader)
  const representing = expand(
    value,
    value._expanded,
    ExpansionType.CompetitionCommunity
  )
  return {
    ...value,
    competition,
    leader,
    representing: {
      ...representing,
      image:
        value._expanded?.additionalOrganization?.find(
          it =>
            it.community?.id === value.representing?.id &&
            it.organization?.id === orgId
        )?.image || representing?.image
    },
    membersCount: value._membersTotalElements,
    _issues: value._issues || []
  }
}
