import { AvatarProps } from '@mui/material/Avatar'
import AvatarGroup from '@mui/material/AvatarGroup'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { drop, some, take } from 'lodash-es'
import { ReactNode } from 'react'
import { RcAvatar } from './RcAvatar'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'

// mui material avatar group does not support tooltips or other functionality for the "extra" avatar
export const RcAvatarGroup = ({
  max,
  size = 40,
  avatars,
  modal
}: {
  max: number
  size?: number
  avatars?: (AvatarProps & { title?: string })[]
  modal?: ReactNode
}) => {
  if (!avatars) return null

  const toDisplay = avatars.length > max ? take(avatars, max - 1) : avatars
  const extraCount = avatars.length - toDisplay.length

  const extraContent = (onClick?: () => any) => (
    <Tooltip
      onClick={onClick}
      title={drop(avatars, toDisplay.length)
        .map(it => it.title)
        .join(', ')}
    >
      <RcAvatar
        variant={
          some(avatars, it => it.variant === 'rounded') ? 'rounded' : 'circular'
        }
        sx={{
          width: size,
          height: size,
          cursor: onClick ? 'pointer' : 'inherit'
        }}
      >
        <Typography
          variant={size < 40 ? 'subtitle2' : 'body1'}
          sx={theme => ({
            color: theme.palette.getContrastText(theme.palette.primary.main)
          })}
        >
          +{extraCount}
        </Typography>
      </RcAvatar>
    </Tooltip>
  )

  return (
    <AvatarGroup>
      {toDisplay.map(({ title, ...it }, idx) => (
        <Tooltip title={title} key={it.id || `idx${idx}`}>
          <RcAvatar {...it} key={it.key} sx={{ width: size, height: size }} />
        </Tooltip>
      ))}
      {extraCount ? (
        modal ? (
          <ModalTrigger
            activation={handleOpen => extraContent(handleOpen)}
            modalProps={{
              noFooter: true,
              noPadding: true
            }}
          >
            {() => modal}
          </ModalTrigger>
        ) : (
          extraContent()
        )
      ) : null}
    </AvatarGroup>
  )
}
