import { Theme } from '@mui/material'
import Box from '@mui/material/Box'
import Card from '@mui/material/Card'
import CardContent from '@mui/material/CardContent'
import { ReactNode } from 'react'
import { calcElevationString } from '@/style/palette'

const TriangleSx = (
  theme: Theme,
  size: number,
  offset: number | string,
  top: number = 0
) => ({
  position: 'absolute',
  top: -size + top,
  left: offset,
  width: size,
  height: size,
  backgroundColor: theme.palette.primary.main,
  backgroundImage: calcElevationString(theme.palette.mode, [6]),
  transform: 'rotate(45deg)'
})

export const ChatBubble = ({
  message,
  offset = '15px'
}: {
  message?: ReactNode
  offset?: string | number
}) => {
  return (
    <Box sx={{ position: 'relative' }}>
      <Box sx={theme => TriangleSx(theme, 10, offset, 5)} />
      <Card
        elevation={6}
        sx={{
          width: '100%',
          mt: 3,
          transform: `rotate(0deg)` // transform so zindex works with arrow
        }}
      >
        <CardContent>{message}</CardContent>
      </Card>
    </Box>
  )
}
