import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Card from '@mui/material/Card'
import CardActionArea from '@mui/material/CardActionArea'
import Slider from '@mui/material/Slider'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  getAbsoluteZoom,
  getZoomFactor
} from 'advanced-cropper/extensions/absolute-zoom'
import { getAuth, signInAnonymously } from 'firebase/auth'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { last } from 'lodash-es'
import { ChangeEvent, useEffect, useRef, useState } from 'react'
import { CircleStencil, Cropper, CropperRef } from 'react-advanced-cropper'
import 'react-advanced-cropper/dist/style.css'
import { v4 as uuidv4 } from 'uuid'
import { set } from 'date-fns'
import { ConfirmingButton } from '../interactive/ConfirmingButton'
import { calcElevationString } from '@/style/palette'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'
import { IMAGE_TYPES } from '@/components/pages/CMS/dynamic/Gallery'
import { usePage } from '@/components/providers/site/PageProvider'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import useDragging from '@/core/hooks/useDragging'

interface FileInputProps {
  source?: string
  saveAs?: string
  autoSubmit?: boolean
  aspectRatio?: number
  circle?: boolean
  allowAnyFile?: boolean
  onComplete: (url: string) => Promise<any>
  onClear: () => Promise<any>
}

interface Image {
  type?: string
  src: string
}

export const RcFileInput = (props: FileInputProps) => {
  const fileDropRef = useRef<HTMLButtonElement>(null)
  const fileInputRef = useRef<HTMLInputElement>(null)
  const cropperRef = useRef<CropperRef>(null)
  const [image, setImage] = useState<Image | null>(null)
  const [error, setError] = useState<string | null>(null)
  const [zoom, setZoom] = useState(0)
  const { open } = usePage()
  const { user, firebase } = useFirebase()

  const saveBlob = async (blob: Blob, ext: string) => {
    const db = getStorage(firebase)
    const r = await ref(
      db,
      `/upload/${user?.uid}/${props.saveAs || uuidv4()}.${ext}`
    )
    const res = await uploadBytes(r, blob)
    const url = await getDownloadURL(res.ref)
    await props.onComplete(url)
  }

  const onLoadImage = (event: ChangeEvent<HTMLInputElement>) => {
    const { files } = event.target

    if (files && files[0]) {
      const blob = URL.createObjectURL(files[0])

      const ext = last(files[0].name?.split('.')) || ''
      const type = files[0].type

      setError(null)
      if (props.allowAnyFile) {
        saveBlob(files[0], ext)
      } else if (IMAGE_TYPES.includes(`.${ext}`)) {
        setImage({
          src: blob,
          type: type
        })
      } else {
        setError(`Invalid filetype, must be one of [${IMAGE_TYPES.join(', ')}]`)
      }
    }

    // Clear the event target value to give the possibility to upload the same image
    event.target.value = ''
  }

  const onSaveImage = async () => {
    try {
      const canvas = cropperRef.current?.getCanvas()
      if (!canvas) return

      canvas.toBlob(async blob => {
        if (blob) {
          await saveBlob(blob, 'png')
        }
      }, 'image/png')
    } catch (error) {
      open(null, `unable to upload file ${error}`, 6000)
    }
  }

  const onZoom = (value: number, transitions?: boolean) => {
    const state = cropperRef.current?.getState()
    const settings = cropperRef.current?.getSettings()
    cropperRef.current?.zoomImage(getZoomFactor(state!, settings!, value), {
      transitions: !!transitions
    })
  }

  // enable drag and drop of single file for upload
  useDragging({
    fileDropRef,
    fileInputRef,
    disabled: false,
    handleChanges: async val => {
      onLoadImage({ target: { files: val } } as any)
    }
  })

  useEffect(() => {
    if (!user) {
      signInAnonymously(getAuth(firebase))
    }
  }, [user, firebase])

  useEffect(() => {
    // Revoke the object URL, to allow the garbage collector to destroy the uploaded file
    return () => {
      if (image && image.src) {
        URL.revokeObjectURL(image.src)
      }
    }
  }, [image])

  return (
    <>
      <Box position='relative' width={'100%'} height={'100%'}>
        <Card
          component={CardActionArea}
          ref={fileDropRef}
          sx={theme => ({
            backgroundImage: calcElevationString(theme.palette.mode, [5]),
            cursor: 'pointer',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: 100,
            height: '100%'
          })}
        >
          {props.source ? (
            <ImageOrFileDisplay
              url={props.source}
              isImage={!props.allowAnyFile}
            />
          ) : error ? (
            <Typography color='error' textAlign='center'>
              {error}
            </Typography>
          ) : (
            <Typography color='text.secondary' textAlign='center'>
              <RcTrans i18nKey='shared.click-or-drag-to-upload' />
            </Typography>
          )}
          <input
            style={{ display: 'none' }}
            ref={fileInputRef}
            id='file'
            name='file'
            type='file'
            accept={props.allowAnyFile ? '*' : 'image/*'}
            onChange={onLoadImage}
          />
        </Card>

        {props.source && !props.circle ? (
          <ConfirmingButton
            color='error'
            sx={{
              position: 'absolute',
              top: 5,
              right: 5,
              border: 0,
              borderColor: 'error',
              backgroundColor: 'background.default',
              '&:hover': {
                backgroundColor: 'background.paper'
              }
            }}
            message={<RcTrans i18nKey='error.confirmation.file-input' />}
            buttonName={<RcTrans i18nKey='shared.delete' />}
            size='small'
            icon={['fas', 'trash']}
            onClick={props.onClear}
          />
        ) : null}
      </Box>

      <ModalTrigger
        open={!!image?.src}
        onClose={() => setImage(null)}
        modalProps={{ noHeader: true }}
      >
        {({ handleClose }) => (
          <>
            <Box height={400} minHeight={400}>
              <Cropper
                style={{ minHeight: 400 }}
                ref={cropperRef}
                src={image?.src}
                stencilProps={{ aspectRatio: props.aspectRatio }}
                stencilComponent={props.circle ? CircleStencil : undefined}
                onChange={v => {
                  const state = v.getState()
                  const settings = v.getSettings()
                  setZoom(getAbsoluteZoom(state, settings) * 100)
                }}
              />
            </Box>

            <Stack direction='row' alignItems={'center'} spacing={5} pt={3}>
              <RcIcon icon={['fal', 'magnifying-glass-minus']} />
              <Slider
                value={zoom || 0}
                step={5}
                min={0}
                max={100}
                onChange={(_, v) => onZoom((v as number) / 100, true)}
              />
              <RcIcon icon={['fal', 'magnifying-glass-plus']} />
            </Stack>
            <ModalConfiguration>
              <Stack direction='row' spacing={3}>
                <Button fullWidth variant='outlined' onClick={handleClose}>
                  <RcTrans i18nKey='shared.cancel' />
                </Button>
                <Button
                  fullWidth
                  onClick={() => {
                    onSaveImage()
                    handleClose()
                  }}
                >
                  <RcTrans i18nKey='shared.save' />
                </Button>
              </Stack>
            </ModalConfiguration>
          </>
        )}
      </ModalTrigger>
    </>
  )
}

export const ImageOrFileDisplay = ({
  url,
  isImage
}: {
  url: string
  isImage?: boolean
}) => {
  const lowered = url?.toLocaleLowerCase()
  return isImage || IMAGE_TYPES.some(it => lowered?.includes(it)) ? (
    <img
      src={url}
      alt={'file upload'}
      style={{
        height: '100%',
        maxWidth: '100%'
      }}
    />
  ) : (
    <Stack direction='column' spacing={3} padding={5}>
      <RcIcon size='5x' icon={['fal', 'file-contract']} />
      <NavigationLink href={url} underline='hover'>
        <Typography>
          {lowered?.substring(
            lowered?.lastIndexOf('%2') + 3,
            lowered?.lastIndexOf('?')
          )}
        </Typography>
      </NavigationLink>
    </Stack>
  )
}
