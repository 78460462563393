import Typography from '@mui/material/Typography'
import { makeStyles } from '@mui/styles'
import { getDownloadURL, getStorage, ref, uploadBytes } from 'firebase/storage'
import { first, last } from 'lodash-es'
import { useRef, useState } from 'react'
import ReactMde from 'react-mde'
import 'react-mde/lib/styles/css/react-mde-all.css'
import { v4 as uuidv4 } from 'uuid'
import { RcTrans } from '@/components/atoms/RcTrans'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { MarkdownDisplay } from '@/components/molecules/input/MarkdownDisplay'
import { useFirebase } from '@/components/providers/site/FirebaseProvider'
import useDragging from '@/core/hooks/useDragging'
import { isDarkMode } from '@/core/utils'
import { calcElevationString } from '@/style/palette'

const useStyles = makeStyles(theme => {
  return {
    reactMde: {
      '&.react-mde': {
        borderRadius: theme.shape.borderRadius,
        border: 0,
        outline: `1px solid ${theme.palette.divider}`
      }
    },
    textArea: {
      color: theme.palette.text.primary,
      borderBottomLeftRadius: theme.shape.borderRadius,
      borderBottomRightRadius: theme.shape.borderRadius,
      backgroundColor: theme.palette.primary.main,
      backgroundImage: calcElevationString(theme.palette.mode, [3]),
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.htmlFontSize,
      '&:hover': {
        outline: `1px solid ${
          isDarkMode(theme)
            ? theme.palette.common.white
            : theme.palette.common.black
        }`
      },
      '&:focus': {
        outline: `2px solid ${theme.palette.primary.main}`
      }
    },
    toolbar: {
      '& *': {
        fontFamily: theme.typography.fontFamily,
        color: theme.palette.text.primary
      },
      '&.mde-header': {
        color: theme.palette.text.secondary,
        borderBottomColor: theme.palette.divider,
        borderTopLeftRadius: theme.shape.borderRadius,
        borderTopRightRadius: theme.shape.borderRadius,
        backgroundColor: theme.palette.primary.main,
        backgroundImage: calcElevationString(theme.palette.mode, [6])
      }
    }
  }
})

/* Wraps ReactMde for constent styling and behavior */
export const MarkdownInput = ({
  value = '',
  onChange
}: {
  value?: string
  onChange: (val: string) => any
}) => {
  const classes = useStyles()
  const { user, firebase } = useFirebase()
  const [selectedTab, setSelectedTab] = useState<'write' | 'preview'>('write')
  const fileDropRef = useRef<HTMLDivElement>(null)

  // enable drag and drop of single file for upload
  useDragging({
    fileDropRef,
    handleChanges: async files => {
      const file = first(files)
      if (!file) return
      const uuid = uuidv4()
      const ext = last(file.name?.split('.'))
      onChange((value += ` ![](saving ${uuid}...)`))
      const db = getStorage(firebase)
      const r = await ref(db, `/upload/${user?.uid}/${uuid}.${ext}`)
      const res = await uploadBytes(r, files[0])
      const url = await getDownloadURL(res.ref)
      onChange(value.replace(`saving ${uuid}...`, url))
    }
  })

  return (
    <div ref={fileDropRef}>
      <ReactMde
        value={value}
        onChange={onChange}
        selectedTab={selectedTab}
        onTabChange={setSelectedTab}
        classes={{
          reactMde: classes.reactMde,
          textArea: classes.textArea,
          toolbar: classes.toolbar
        }}
        generateMarkdownPreview={async md => <MarkdownDisplay markdown={md} />}
      />
      <Typography variant='subtitle2' pt={1}>
        <NavigationLink href='https://commonmark.org/help/'>
          <RcTrans i18nKey='site.markdown-hint' />
        </NavigationLink>
      </Typography>
    </div>
  )
}
