import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Stack from '@mui/material/Stack'
import TableCell from '@mui/material/TableCell'
import TableRow, { TableRowProps } from '@mui/material/TableRow'
import { SidType } from '@rallycry/api-suite-typescript'
import { CompetitionParticipantResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionParticipantResource'
import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'
import { some } from 'lodash-es'
import dynamic from 'next/dynamic'
import { CompetitionIssueAlertTrigger } from '../alerts/CompetitionAlertFormTrigger'
import { ContactParticipantsModal } from '../shared/contact/ContactParticipantsModal'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  ActionMenu,
  ActionMenuOption
} from '@/components/molecules/interactive/ActionMenu'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { UserAvatar } from '@/components/organisms/avatar/UserAvatar'
import { UserNetwork } from '@/components/organisms/user/UserNetwork'
import { ErrorCode } from '@/core/error-code'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import {
  TeamRank,
  useCompetitionTeamMembers
} from '@/entity/competition-team/useCompetitionTeamMembers'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionMeta } from '@/entity/competition/useCompetitionMeta'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { useCompetitionTeamContactAccounts } from '@/entity/competition-team/useCompetitionTeamContactAccounts'
import { TeamRosterUser } from './TeamRosterUser'

export interface TeamRosterRowProps
  extends TableRowProps,
    ReturnType<typeof useCompetitionTeamMembers> {
  user: UserResource & { rank?: TeamRank }
  isSoloCompetition: boolean
  canEdit: boolean
  canPromote: boolean
  teamSizeMax?: number
}

export const TeamRosterRow = ({
  user,
  isSoloCompetition,
  canEdit,
  canPromote,
  transferLeadership,
  removeMember,
  addMember,
  removeAssistant,
  promoteMember,
  teamSizeMax,
  ...rest
}: TeamRosterRowProps) => {
  const isMemberOfTeam = !!user?.rank
  const isRealUser = user?.ownerType !== SidType.COMMUNITYOFFICER
  const { roster } = useCompetitionTeamMembers()

  const currentTeamSize = roster.length
  const canAddMember = currentTeamSize < teamSizeMax!

  const options: ActionMenuOption[] = []

  if (user.rank === TeamRank.HeadCoach) {
    options.push({
      key: 'convert-to-captain',
      display: <RcTrans i18nKey='competition:roster.convert-to-captain' />,
      action: async () => addMember(user?.id!)
    })
  } else if (user.rank === TeamRank.HeadCaptain) {
    options.push({
      key: 'is-captain',
      display: <RcTrans i18nKey='competition:roster.convert-to-coach' />,
      action: async () => removeMember(user?.id!)
    })
  } else if (user.rank === TeamRank.AssistantCoach) {
    canPromote &&
      isRealUser &&
      options.push({
        key: 'transfer-leadership',
        display: <RcTrans i18nKey='competition:roster.transfer-leadership' />,
        action: async () => transferLeadership(user?.id!),
        confirmation: (
          <RcTrans i18nKey='competition:roster.transfer-confirmation' />
        )
      })
    canPromote &&
      options.push({
        key: 'remove-coach',
        display: <RcTrans i18nKey='competition:roster.remove-coach' />,
        action: async () => removeAssistant(user?.id!)
      })
  } else if (user.rank === TeamRank.AssistantCaptain) {
    canPromote &&
      options.push({
        key: 'convert-to-member',
        display: <RcTrans i18nKey='competition:roster.convert-to-member' />,
        action: async () => removeAssistant(user?.id!)
      })
    canPromote &&
      isRealUser &&
      options.push({
        key: 'transfer-leadership',
        display: <RcTrans i18nKey='competition:roster.transfer-leadership' />,
        action: async () => transferLeadership(user?.id!)
      })
    canPromote &&
      options.push({
        key: 'remove-member',
        display: <RcTrans i18nKey='competition:roster.remove-member' />,
        action: async () => {
          await removeMember(user?.id!)
          await removeAssistant(user?.id!)
        }
      })
  } else if (user.rank === TeamRank.Member) {
    canPromote &&
      options.push({
        key: 'convert-to-captain',
        display: <RcTrans i18nKey='competition:roster.convert-to-captain' />,
        action: async () => promoteMember(user?.id!)
      })
    options.push({
      key: 'remove-member',
      display: <RcTrans i18nKey='competition:roster.remove-member' />,
      action: async () => removeMember(user?.id!)
    })
  } else {
    canPromote &&
      options.push({
        key: 'add-coach',
        display: <RcTrans i18nKey='competition:roster.add-as-coach' />,
        action: async () => promoteMember(user?.id!)
      })
    canAddMember &&
      options.push({
        key: 'add-member',
        display: <RcTrans i18nKey='competition:roster.add-as-member' />,
        action: async () => {
          return addMember(user?.id!)
        }
      })
  }

  return (
    <TableRow {...rest}>
      <TableCell sx={{ maxWidth: 200, overflow: 'hidden' }}>
        <TeamRosterUser user={user} hideChip />
      </TableCell>
      <TableCell align='right'>
        <Stack
          direction='row'
          spacing={2}
          justifyContent={'flex-end'}
          alignItems='center'
        >
          {canEdit ? (
            <RcSuspense skipError skipLoader>
              <MissingPermitIssue user={user} participant={user} />
              <MissingFormIssues participant={user} />
            </RcSuspense>
          ) : null}

          {canEdit && isMemberOfTeam ? (
            <ContactParticipantsModal
              participants={[user?.id!]}
              label={
                <RcIconButton
                  icon={['fal', 'envelope']}
                  TooltipProps={{
                    title: (
                      <RcTrans
                        i18nKey='competition:contact.modal.title'
                        tOptions={{ count: 1 }}
                      />
                    )
                  }}
                />
              }
            />
          ) : null}

          {canEdit ? (
            some(options) ? (
              <ActionMenu options={options} />
            ) : canAddMember ? (
              <RcIconButton
                onClick={() => addMember(user?.id!)}
                icon={['fal', 'plus']}
                TooltipProps={{
                  title: <RcTrans i18nKey='competition:roster.add-as-member' />
                }}
              />
            ) : null
          ) : null}
        </Stack>
      </TableCell>
    </TableRow>
  )
}

const MissingPermitIssue = ({
  participant,
  user
}: {
  participant: UserResource
  user: UserResource
}) => {
  const {
    team,
    read: { mutate: mutateTeam }
  } = useCompetitionTeam()

  const { account } = useUserAccount()
  const userId = participant.id
  const isSelfRow = userId === account?.id

  const issue = team?._issues?.find(
    issue => issue.code === ErrorCode.PermitCountMin
  ) as any

  const missing =
    issue &&
    !issue?.context?.usersPermitted?.find(
      (it: number) => it === participant?.id
    )

  if (!missing) return null

  return (
    <CompetitionPermitPurchaseButton
      giftToUser={isSelfRow ? undefined : user}
      activation={handleOpen => (
        <IssueButton
          onClick={handleOpen}
          message={<RcTrans i18nKey='competition:permit-response-missing' />}
        />
      )}
      onComplete={mutateTeam}
    />
  )
}

const CompetitionPermitPurchaseButton = dynamic(
  async () =>
    (await import('../shared/CompetitionPermitPurchaseButton'))
      .CompetitionPermitPurchaseButton,
  { ssr: false, loading: () => <></> }
)

const MissingFormIssues = ({
  participant
}: {
  participant: CompetitionParticipantResource
}) => {
  const { team } = useCompetitionTeam()

  const userId = participant?.id

  const formIssue = team?._issues?.find(
    issue => issue.code === ErrorCode.FormMissingResponse
  ) as any

  const missingForms = formIssue?.context?.missingResponses?.filter(
    (response: any) =>
      !response.assigned &&
      !response.completed &&
      response.participants?.find((it: any) => it === userId)
  )

  return missingForms?.map((it: any) => (
    <MissingFormIssue
      key={it.name}
      participant={participant}
      missingForm={it}
    />
  ))
}

const MissingFormIssue = ({
  missingForm,
  participant
}: {
  missingForm: any
  participant: CompetitionParticipantResource
}) => {
  const {
    read: { mutate: mutateTeam }
  } = useCompetitionTeam()
  const {
    read: { mutate: mutateCompetition }
  } = useCompetitionMeta()
  const { isCompetitionModerator } = useCompetition()

  const { account } = useUserAccount()
  const userId = participant.participant?.id
  const isSelfRow = userId === account?.id

  const response = missingForm?.responses?.find((it: any) => it.user === userId)

  const competitionFormId = missingForm?.competitionForm?.id
  const responseId = response?.id

  const formName = missingForm?.competitionForm?.formName || 'Form'

  return isSelfRow || isCompetitionModerator ? (
    <CompetitionIssueAlertTrigger
      competitionFormId={competitionFormId}
      responseId={responseId}
      onComplete={async () => {
        await mutateCompetition()
        await mutateTeam()
      }}
      activation={handleOpen => (
        <IssueButton
          onClick={handleOpen}
          message={
            <RcTrans
              i18nKey='shared.form-response-missing'
              tOptions={{ name: formName }}
            />
          }
        />
      )}
    />
  ) : (
    <IssueButton
      message={
        <RcTrans
          i18nKey='shared.form-response-missing'
          tOptions={{ name: formName }}
        />
      }
    />
  )
}

const IssueButton = ({
  onClick,
  message
}: {
  onClick?: () => any
  message: React.ReactNode
}) => {
  return (
    <RcIconButton
      onClick={onClick}
      size='small'
      color='warning'
      icon={['fal', 'exclamation-triangle']}
      TooltipProps={{
        title: message!,
        enterTouchDelay: 0,
        arrow: true
      }}
    />
  )
}

export const UserCompetitionContacts = ({ userId }: { userId?: number }) => {
  const { contacts } = useCompetitionTeamContactAccounts()

  return (
    <Stack direction='row' spacing={1}>
      {contacts
        ?.filter(it => it.user?.id === userId)
        ?.map((account, index) => (
          <UserNetwork key={index} account={account} condensed disableDelete />
        ))}
    </Stack>
  )
}
