import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableContainer from '@mui/material/TableContainer'

import { SearchDependentsRow } from './SearchDependentsRow'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { PageItemContent } from '@/components/organisms/page/PageItemContent'
import { useCompetitionTeamMembers } from '@/entity/competition-team/useCompetitionTeamMembers'
import { useUserFamilyMembers } from '@/entity/user/useUserFamilyMembers'
import { CreateEditDependentFlow } from '@/flows/Site/CreateDependentFlow/_CreateDependentFlow'

export const SearchDependents = () => {
  const { family } = useUserFamilyMembers()
  const { roster, addMember } = useCompetitionTeamMembers()
  const memberIds = roster.map(it => it?.id)

  return (
    <>
      <TableContainer>
        <Table>
          <TableBody>
            {family.map(it => (
              <SearchDependentsRow
                key={it.id}
                user={it}
                isInRoster={memberIds.includes(it?.id)}
              />
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <ModalConfiguration
        title={<RcTrans i18nKey='competition:team.suggestions-header' />}
        subtitle={
          <PageItemContent>
            <RcTrans i18nKey='competition:team.suggestions-subtitle' />
          </PageItemContent>
        }
      >
        <ModalTrigger
          activation={handleOpen => (
            <RcButton fullWidth onClick={handleOpen}>
              <RcTrans i18nKey='home:create-child-flow.create-and-add-button' />
            </RcButton>
          )}
        >
          {({ handleClose }) => (
            <CreateEditDependentFlow
              onComplete={async user => {
                await addMember(user.id!)
                handleClose()
              }}
            />
          )}
        </ModalTrigger>
      </ModalConfiguration>
    </>
  )
}
