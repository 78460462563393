import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import TextField from '@mui/material/TextField'
import React, { PropsWithChildren } from 'react'
import { RcIconButton } from '../interactive/RcIconButton'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { RcIcon } from '@/components/atoms/RcIcon'

interface SearchBoxProps {
  filter: string
  setFilter: React.Dispatch<React.SetStateAction<string>>
  placeholder?: string
  disabled?: boolean
}

export const SearchBox = ({
  filter,
  setFilter,
  placeholder,
  disabled,
  children
}: PropsWithChildren<SearchBoxProps>) => {
  const { t } = useRcTranslation() // ph
  const resolvedPlaceholder = placeholder
    ? placeholder
    : t('shared.search-placeholder')

  return (
    <Box>
      <Grid
        item
        container
        direction='row'
        alignItems='center'
        justifyContent='space-between'
        spacing={4}
      >
        <Grid item xs>
          <TextField
            disabled={disabled}
            placeholder={resolvedPlaceholder}
            value={filter}
            variant='outlined'
            fullWidth
            onChange={val => setFilter(val.target.value)}
            InputProps={{
              startAdornment: <RcIcon icon={['fal', 'search']} size='sm' />,
              endAdornment: filter ? (
                <RcIconButton
                  icon={['fal', 'times']}
                  onClick={() => setFilter('')}
                />
              ) : null
            }}
          />
        </Grid>
        {children ? <Grid item>{children}</Grid> : null}
      </Grid>
    </Box>
  )
}
