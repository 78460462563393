import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Tooltip from '@mui/material/Tooltip'
import Typography from '@mui/material/Typography'
import { CompetitionParticipantDocument } from '@rallycry/api-suite-typescript/dist/models/CompetitionParticipantDocument'

import { some } from 'lodash-es'
import { useMemo } from 'react'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { ChatBubble } from '@/components/molecules/text/ChatBubble'
import { EllipsisBox } from '@/components/molecules/text/EllipsisBox'
import { RcAvatarGroup } from '@/components/molecules/text/RcAvatarGroup'
import { UserAvatar } from '@/components/organisms/avatar/UserAvatar'
import { SharedCommunities } from '@/components/organisms/user/SharedCommunities'
import { DiscoverCard } from '@/components/organisms/card/DiscoverCard'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import { useCompetitionTeamInvitations } from '@/entity/competition-team/useCompetitionTeamInvitations'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'
import { useUserSelfCommunities } from '@/entity/user/useUserSelfCommunities'
import { useApiError } from '@/core/hooks/useApiError'
import { useParsedParam } from '@/core/hooks/useRouteParams'

export interface FreeAgentCardProps {
  participantDisplay: CompetitionParticipantDocument
}

export const FreeAgentCard = ({ participantDisplay }: FreeAgentCardProps) => {
  const { settings } = useCompetition()
  const { participant } = useCompetitionParticipant()
  const teamId = useParsedParam('teamId')
  const { team, isLeader } = useCompetitionTeam({
    idOrKey: participant?.entry?.id || teamId
  })
  const { invite, invitations } = useCompetitionTeamInvitations({
    idOrKey: participant?.entry?.id || teamId
  })
  const { handle } = useApiError()
  const { communities } = useUserSelfCommunities()

  const participantDisplayId = participantDisplay?.participant?.id
  const hasTeam = !!team
  const hasInvited = invitations?.find(
    it => it?.recipient?.id === participantDisplayId
  )
  const maxInvites = settings?.teamSizeMax! * 2
  const availableInvites = maxInvites - invitations.length

  const handleInvite = async () => {
    if (!participantDisplayId) return
    try {
      await invite(participantDisplayId)
    } catch (error) {
      await handle(error)
    }
  }
  const intersectingCommunities = useMemo(
    () =>
      participantDisplay.representing?.filter(
        audience => communities?.find(c => c.community?.id === audience?.id)
      ) || [],
    [participantDisplay, communities]
  )
  const audienceText = (
    <SharedCommunities
      communities={intersectingCommunities}
      user={participantDisplay.participant}
    />
  )

  return (
    <DiscoverCard
      header={
        <Box p={3} pb={0}>
          <UserAvatar user={participantDisplay.participant!} />

          <ChatBubble
            message={
              <Tooltip title={participantDisplay.description}>
                <Box
                  minHeight={30}
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <EllipsisBox lineClamp={2}>
                    <Typography variant='body2'>
                      {participantDisplay.description}
                    </Typography>
                  </EllipsisBox>
                </Box>
              </Tooltip>
            }
          />
        </Box>
      }
      content={
        <Stack direction='row' spacing={2} alignItems='center' height={32}>
          <RcAvatarGroup
            max={3}
            size={24}
            avatars={
              some(participantDisplay.representing)
                ? participantDisplay.representing?.map(it => ({
                    key: it.id,
                    src: it.image,
                    title: it.name
                  }))
                : undefined
            }
          />
          <Tooltip title={audienceText}>
            <span>
              <EllipsisBox lineClamp={2} breakAll={false}>
                <Typography variant='body2' color='text.secondary'>
                  {audienceText}
                </Typography>
              </EllipsisBox>
            </span>
          </Tooltip>
        </Stack>
      }
      actions={
        isLeader ? (
          <>
            {hasInvited ? (
              <RcButton variant='outlined' disabled fullWidth>
                <RcTrans i18nKey='competition:team.invited-button' />
              </RcButton>
            ) : (
              <RcButton
                onClick={handleInvite}
                disabled={!availableInvites}
                fullWidth
              >
                {availableInvites > 0 ? (
                  <RcTrans i18nKey='shared.invite' />
                ) : (
                  <RcTrans i18nKey='competition:invite-flow.find-users.no-remaining-invites-button' />
                )}
              </RcButton>
            )}
          </>
        ) : hasTeam ? (
          <Tooltip
            title={
              <RcTrans i18nKey='competition:team.only-leader-can-invite' />
            }
          >
            <Box width='100%'>
              <RcButton variant='outlined' disabled fullWidth>
                <RcTrans i18nKey='shared.invite' />
              </RcButton>
            </Box>
          </Tooltip>
        ) : (
          <Tooltip title={<RcTrans i18nKey='competition:team.create-a-team' />}>
            <Box width='100%'>
              <RcButton variant='outlined' disabled fullWidth>
                <RcTrans i18nKey='shared.invite' />
              </RcButton>
            </Box>
          </Tooltip>
        )
      }
    />
  )
}
