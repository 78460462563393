import { FreeAgentDisplay } from '../registration/FreeAgentDisplay'
import { RcTrans } from '@/components/atoms/RcTrans'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { PageItemContent } from '@/components/organisms/page/PageItemContent'

export const SearchFreeAgents = () => {
  return (
    <PageItemContent pt={0}>
      <ModalConfiguration
        title={<RcTrans i18nKey='competition:team.free-agent-header' />}
      />

      <FreeAgentDisplay isModal />
    </PageItemContent>
  )
}
