import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { useState } from 'react'
import { SearchDependents } from './SearchDependents'
import { SearchFreeAgents } from './SearchFreeAgents'
import { SearchUsers } from './SearchUsers'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import { PageItemContent } from '@/components/organisms/page/PageItemContent'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { RcPaperButton } from '@/components/molecules/interactive/RcPaperButton'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'

enum InviteFlowStep {
  SELECTING = 'selecting',
  USER = 'user',
  DEPENDENTS = 'dependents',
  FREE_AGENT = 'free-agent'
}

export const TeamInviteFlow = () => {
  const { isFreeAgentMode, settings } = useCompetition()
  const { copyTeamInvite } = useCompetitionTeam()

  const options = []

  settings?.allowChildUsers &&
    options.push({
      key: InviteFlowStep.DEPENDENTS,
      icon: ['fal', 'family'] as IconProp,
      title: <RcTrans i18nKey='competition:invite.search-dependents-title' />
    })

  isFreeAgentMode &&
    options.push({
      key: InviteFlowStep.FREE_AGENT,
      icon: ['fal', 'user-bounty-hunter'] as IconProp,
      title: <RcTrans i18nKey='competition:invite.search-free-agents-title' />
    })

  options.push({
    key: InviteFlowStep.USER,
    icon: ['fal', 'user'] as IconProp,
    title: <RcTrans i18nKey='competition:invite.search-by-name-title' />
  })

  const [step, setStep] = useState<InviteFlowStep>(
    options.length === 1 ? options[0].key : InviteFlowStep.SELECTING
  )

  return (
    <>
      {
        {
          [InviteFlowStep.SELECTING]: (
            <PageItemContent>
              <Stack direction='column' spacing={5}>
                <RcPaperButton onClick={copyTeamInvite} selected>
                  <Box
                    display='flex'
                    flexDirection='column'
                    alignItems='center'
                  >
                    <RcIcon mb={3} icon={['fal', 'copy']} size='lg' />

                    <Typography variant='body0' align='center'>
                      <RcTrans i18nKey='competition:team.create-invite-link' />
                    </Typography>
                  </Box>
                </RcPaperButton>
                {options.map(it => (
                  <RcPaperButton
                    key={it.key}
                    onClick={() => setStep(it.key)}
                    selected
                  >
                    <Box
                      display='flex'
                      flexDirection='column'
                      alignItems='center'
                    >
                      <RcIcon mb={3} icon={it.icon} size='lg' />

                      <Typography variant='body0' align='center'>
                        {it.title}
                      </Typography>
                    </Box>
                  </RcPaperButton>
                ))}
              </Stack>
            </PageItemContent>
          ),
          [InviteFlowStep.USER]: <SearchUsers />,
          [InviteFlowStep.DEPENDENTS]: <SearchDependents />,
          [InviteFlowStep.FREE_AGENT]: <SearchFreeAgents />
        }[step]
      }

      <ModalConfiguration
        topAction={
          options.length === 1 || step === InviteFlowStep.SELECTING
            ? undefined
            : () => setStep(InviteFlowStep.SELECTING)
        }
      />
    </>
  )
}
