import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Chip from '@mui/material/Chip'
import Skeleton from '@mui/material/Skeleton'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { EventKind } from '@rallycry/api-suite-typescript/dist/models'
import { CommunityKind } from '@rallycry/api-suite-typescript/dist/models/CommunityKind'
import { isEmpty, last, some } from 'lodash-es'
import { BulkAchievementAssignFlow } from '../../../../organisms/user/BulkAchievementAssignModal'
import { isActiveCheckinIssue } from '../alerts/useCompetitionAlertsCheckin'
import { CloneTeams } from '../shared/CloneTeams'
import { TeamJoinButton } from '../shared/TeamJoinButton'
import { ContactParticipantsFlow } from '../shared/contact/ContactParticipantsModal'
import { TeamInviteFlow } from './TeamInviteFlow'
import { TeamSettings } from './TeamSettings'
import { TeamNoteForm } from './note/TeamNoteForm'
import { TeamMilliRatingForm } from './TeamMilliRatingForm'
import { RcIcon } from '@/components/atoms/RcIcon'
import { RcSuspense } from '@/components/atoms/RcSuspense'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  ActionMenu,
  ActionMenuOption
} from '@/components/molecules/interactive/ActionMenu'
import { ConfirmingButton } from '@/components/molecules/interactive/ConfirmingButton'
import { RcAvatar } from '@/components/molecules/text/RcAvatar'
import { ModalTrigger } from '@/components/organisms/modal/ModalTrigger'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { Trophy } from '@/components/organisms/site/Trophy'
import { spinOnHover } from '@/core/animations'
import { ExpansionType, expand } from '@/core/expand'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useParsedParam } from '@/core/hooks/useRouteParams'
import { CompetitionRoute, RootRoute } from '@/core/route-keys'
import { useBracketMatchesUber } from '@/entity/bracket-match/useBracketMatchesUber'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import { useCompetitionTeamImage } from '@/entity/competition-team/useCompetitionTeamImage'
import { useCompetitionTeamMembers } from '@/entity/competition-team/useCompetitionTeamMembers'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useCompetitionActions } from '@/entity/competition/useCompetitionActions'
import { useCompetitionEvents } from '@/entity/competition/useCompetitionEvents'
import { useCompetitionParticipant } from '@/entity/competition/useCompetitionParticipant'

export const TeamHeader = ({ condensed }: { condensed?: boolean }) => {
  const {
    isCompetitionModerator,
    isSoloCompetition,
    isSingleRestrictedCommunity
  } = useCompetition()
  const { participant } = useCompetitionParticipant()
  const {
    team,
    drop,
    unDrop,
    remove,
    copyTeamInvite,
    read: { isLoading, mutate }
  } = useCompetitionTeam()
  const { events, isRegistrationActive } = useCompetitionEvents()
  const { checkin } = useCompetitionActions()
  const { roster, removeMember } = useCompetitionTeamMembers()
  const { getPath, navTo } = useNavigation()
  const parsedId = useParsedParam('teamId')
  const { matches } = useBracketMatchesUber({
    request: { entry: [parsedId] }
  })

  const representingCommunity = expand(
    team,
    team?._expanded,
    ExpansionType.CompetitionCommunity
  )

  const image = useCompetitionTeamImage({
    isSingleRestrictedCommunity,
    isSoloCompetition,
    leader: team?._expanded?.user?.[0],
    representing: representingCommunity
  })

  const canDrop = !!team?._links?.drop
  const canUnDrop = !!team?._links?.unDrop
  const isTeamAdmin = team?._links?.update
  const isOnTeam = participant?.entry?.id === team?.id
  const isTeamOwner = !!team?._links?._delete || isCompetitionModerator
  const hasMatches = some(matches)

  const showDrop = canDrop && (!isRegistrationActive || hasMatches)
  const showUnDrop = canUnDrop

  const teamCanDelete = isTeamOwner && !showDrop && !showUnDrop && !hasMatches
  const adminCanDelete = !hasMatches && isCompetitionModerator
  const showDelete = teamCanDelete || adminCanDelete

  const communityPath = getPath({
    root:
      representingCommunity?.kind === CommunityKind.BASIC
        ? RootRoute.Community
        : RootRoute.Squad,
    rootId: representingCommunity?.id
  })

  const currentOrPreviousCheckins =
    events([EventKind.CHECKIN]).allCurrentOrPrevious?.map(it => it.id!) || []

  const mostRecentCheckin = last(currentOrPreviousCheckins)

  const canCheckin =
    isCompetitionModerator || (mostRecentCheckin && (isOnTeam || isTeamAdmin))

  const handleCheckin = async () => {
    if (!mostRecentCheckin) return
    await checkin(mostRecentCheckin, team?.id!, false)
    await mutate()
  }

  // if competition moderator, clone team, bulk achievement assign, and contact participants, create link to join team, find more modal
  // if team admin, drop from competition (with confirmation), create link to join team, find more modal
  // if on team, show leave team
  // if not on team, show join team

  const participants = roster?.map(it => it?.id!) || []
  const options: ActionMenuOption[] = []

  if (isTeamAdmin || isCompetitionModerator) {
    showDrop &&
      options.push({
        key: 'drop',
        icon: <RcIcon icon={['fal', 'arrow-down-to-line']} />,
        display: <RcTrans i18nKey='competition:team.drop-action' />,
        action: async () => drop(),
        confirmation: (
          <RcTrans i18nKey='competition:team.drop-confirmation-message' />
        )
      })

    showUnDrop &&
      options.push({
        key: 'undrop',
        icon: <RcIcon icon={['fal', 'rotate-left']} />,
        display: <RcTrans i18nKey='competition:team.undrop-action' />,
        action: async () => unDrop()
      })

    showDelete &&
      options.push({
        key: 'disband',
        icon: <RcIcon icon={['fal', 'trash']} />,
        action: async () => {
          navTo({
            root: RootRoute.Competition,
            rootId: team?.competition?.id,
            subRoute: CompetitionRoute.Info
          })
          await remove(team?.id!)
        },
        display: <RcTrans i18nKey='competition:team.disband-action' />,
        confirmation: (
          <RcTrans i18nKey='competition:team.disband-confirmation-message' />
        )
      })
  }

  if (isCompetitionModerator) {
    options.push({
      key: 'note',
      icon: <RcIcon icon={['fal', 'sticky-note']} />,
      display: <RcTrans i18nKey='competition:moderator-note' />,
      modal: ({ handleClose }) => <TeamNoteForm onComplete={handleClose} />
    })
    // options.push({
    //   key: 'rating',
    //   icon: <RcIcon icon={['fal', 'scale-balanced']} />,
    //   display: <RcTrans i18nKey='competition:match.adjust-rating-title' />,
    //   modal: ({ handleClose }) => (
    //     <TeamMilliRatingForm onComplete={handleClose} entrant={team?.id} />
    //   )
    // })

    options.push({
      key: 'clone',
      icon: <RcIcon icon={['fal', 'clone']} />,
      display: <RcTrans i18nKey='competition:team.clone-team-action' />,
      modal: () => <CloneTeams selected={[team?.id!]} />
    })

    options.push({
      key: 'bulk-assign',
      icon: <RcIcon icon={['fal', 'award']} />,
      disabled: !participants.length,
      display: (
        <RcTrans i18nKey='competition:team.bulk-assign-achievement-action' />
      ),
      modal: ({ handleClose }) => (
        <BulkAchievementAssignFlow
          users={participants}
          onComplete={handleClose}
        />
      )
    })

    options.push({
      key: 'contact',
      icon: <RcIcon icon={['fal', 'envelope']} />,
      display: <RcTrans i18nKey='competition:team.email-team-action' />,
      modal: ({ handleClose }) => (
        <ContactParticipantsFlow
          participants={participants}
          onComplete={handleClose}
        />
      )
    })
  }

  const CheckInChip = () => {
    return (
      <Box>
        {team?.dropped ? (
          <Chip
            color='warning'
            label={<RcTrans i18nKey='competition:team.dropped' />}
          />
        ) : isLoading || !team ? (
          <Skeleton variant='rounded' animation={false} />
        ) : isEmpty(currentOrPreviousCheckins) ? null : team?._issues?.find(
            it => isActiveCheckinIssue(it, mostRecentCheckin)
          ) ? (
          <Chip
            label={<RcTrans i18nKey='competition:team.checkin-required' />}
            color='error'
            onClick={canCheckin ? handleCheckin : undefined}
            sx={{
              cursor: canCheckin ? 'pointer' : 'inherit',
              borderRadius: 2
            }}
            icon={<RcIcon icon={['fal', 'times']} ml={1} mr={1} />}
          />
        ) : (
          <Chip
            label={<RcTrans i18nKey='competition:team.checkin-complete' />}
            color='success'
            sx={{ borderRadius: 2 }}
            icon={<RcIcon icon={['fal', 'check']} ml={1} mr={1} />}
          />
        )}
      </Box>
    )
  }

  return (
    <Stack
      direction={condensed ? 'column' : 'row'}
      spacing={condensed ? 2 : 4}
      justifyContent={'space-between'}
      alignItems={condensed ? 'flex-start' : 'center'}
      py={4}
      px={4}
    >
      <Stack direction='row'>
        {representingCommunity ? (
          <NavigationLink to={communityPath}>
            <RcAvatar
              src={image}
              sx={{ width: 60, height: 60, mr: 2 }}
              variant='rounded'
            />
          </NavigationLink>
        ) : (
          <RcAvatar
            src={image}
            sx={{ width: 60, height: 60, mr: 2 }}
            variant='rounded'
          />
        )}

        <Stack direction='column' spacing={1}>
          <Stack
            direction='row'
            spacing={3}
            flexGrow={1}
            pr={2}
            alignItems={'center'}
          >
            <Typography variant='h2' color='text.primary'>
              {team?.alternateName}
              {team?.abbreviation ? ` (${team?.abbreviation})` : null}
            </Typography>
            {team?.rank ? <Trophy placement={team?.rank} size='lg' /> : null}
          </Stack>
          {condensed ? null : <CheckInChip />}
        </Stack>
      </Stack>

      <Stack
        direction='row'
        spacing={condensed ? 1 : 2}
        justifyContent={condensed ? 'flex-start' : 'flex-end'}
        alignItems='center'
        sx={{ width: condensed ? '100%' : undefined }}
      >
        {condensed ? (
          <Box flexGrow={1}>
            <CheckInChip />
          </Box>
        ) : null}
        {isTeamAdmin && !isSoloCompetition ? (
          <Button
            size={condensed ? 'small' : 'medium'}
            variant='outlined'
            onClick={copyTeamInvite}
          >
            <RcIcon icon={['fal', 'copy']} mr={condensed ? 0 : 1} />
            {condensed ? null : (
              <RcTrans i18nKey='competition:team.invite-link' />
            )}
          </Button>
        ) : null}
        <Box>
          <ModalTrigger
            modalProps={{ noFooter: true, noPadding: true }}
            activation={handleOpen => (
              <Button
                variant='outlined'
                onClick={handleOpen}
                size={condensed ? 'small' : 'medium'}
              >
                <RcIcon icon={['fal', 'search']} mr={condensed ? 0 : 1} />
                {condensed ? null : (
                  <RcTrans i18nKey='competition:team.find-more-action' />
                )}
              </Button>
            )}
          >
            {() => <TeamInviteFlow />}
          </ModalTrigger>
        </Box>
        {isTeamAdmin ? (
          <ModalTrigger
            activation={handleOpen => (
              <Button
                size={condensed ? 'small' : 'medium'}
                variant='outlined'
                onClick={handleOpen}
                sx={spinOnHover}
              >
                <RcIcon icon={['fal', 'cog']} mr={condensed ? 0 : 1} />
                {condensed ? null : <RcTrans i18nKey='shared.settings' />}
              </Button>
            )}
          >
            {() => <TeamSettings />}
          </ModalTrigger>
        ) : isOnTeam ? (
          <Box>
            <ConfirmingButton
              size={condensed ? 'small' : 'medium'}
              message={
                <RcTrans i18nKey='competition:team.quit-confirmation-message' />
              }
              buttonName={<RcTrans i18nKey='shared.leave' />}
              onClick={() => removeMember(participant?.participant?.id!)}
            >
              <RcTrans i18nKey='competition:team.quit-action' />
              <RcIcon icon={['fal', 'sign-out']} ml={2} />
            </ConfirmingButton>
          </Box>
        ) : null}
        <RcSuspense skipLoader skipError>
          <TeamJoinButton team={team} condensed={condensed} />
        </RcSuspense>
        {some(options) ? (
          <ActionMenu
            options={options}
            buttonProps={{
              size: condensed ? 'small' : 'medium',
              variant: 'outlined',
              sx: {}
            }}
          />
        ) : null}
      </Stack>
    </Stack>
  )
}
