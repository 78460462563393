import Box from '@mui/material/Box'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import Typography from '@mui/material/Typography'
import { UserResource } from '@rallycry/api-suite-typescript'
import { isEmpty } from 'lodash-es'
import { TeamRosterRow } from './TeamRosterRow'
import {
  TeamRank,
  useCompetitionTeamMembers
} from '@/entity/competition-team/useCompetitionTeamMembers'
import { useCompetition } from '@/entity/competition/useCompetition'

export const TeamRosterSection = ({
  title,
  content,
  canPromote,
  canEdit,
  teamSizeMax
}: {
  title: React.ReactNode
  content: (UserResource & {
    rank?: TeamRank
  })[]
  canPromote: boolean
  canEdit: boolean
  teamSizeMax?: number
}) => {
  const teamMembers = useCompetitionTeamMembers()
  const { isSoloCompetition } = useCompetition()

  if (isEmpty(content)) return null

  return (
    <Box>
      <Typography variant='subtitle1' color='text.secondary' ml={2} mb={1}>
        {title}
      </Typography>
      <Table>
        <TableBody>
          {content?.map(it => (
            <TeamRosterRow
              teamSizeMax={teamSizeMax}
              key={it.id}
              user={it}
              isSoloCompetition={isSoloCompetition}
              canPromote={canPromote}
              canEdit={canEdit}
              {...teamMembers}
            />
          ))}
        </TableBody>
      </Table>
    </Box>
  )
}
