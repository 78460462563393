import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Typography, { TypographyProps } from '@mui/material/Typography'
import { EntityType } from '@rallycry/api-suite-typescript/dist/models/EntityType'
import { InboxMessageResource } from '@rallycry/api-suite-typescript/dist/models/InboxMessageResource'
import { ResolvedArg } from '@rallycry/api-suite-typescript/dist/models/ResolvedArg'
import { groupBy } from 'lodash-es'
import moment from 'moment-timezone'
import {
  AchievementResource,
  CommunityResource
} from '@rallycry/api-suite-typescript/dist/models'
import { NavigationLink } from '../navigation/NavigationLink'
import { TWITCH_PURPLE } from '../twitch/TwitchAlert'
import { useUserAccount } from '@/entity/user/useUserAccount'
import { TranslationNamespace } from '@/core/translation'
import {
  CommunityRoute,
  CommunitySettingsRoute,
  CompetitionRoute,
  RootRoute
} from '@/core/route-keys'
import { useRcTranslation } from '@/core/hooks/useRcTranslation'
import { useNavigation } from '@/core/hooks/useNavigation'
import { RcAvatar } from '@/components/molecules/text/RcAvatar'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcIcon } from '@/components/atoms/RcIcon'
import { useTime } from '@/core/hooks/useTime'
export interface InboxMessageDisplayProps extends TypographyProps {
  emphasisColor?: 'color-linkSecondary' | 'color-linkPrimary'
  message: InboxMessageResource
  onAchievement: (
    achievement: AchievementResource,
    community?: CommunityResource
  ) => void
  render: (message: React.ReactNode) => React.ReactNode
}
export const InboxMessageDisplay: React.FC<InboxMessageDisplayProps> = ({
  emphasisColor = 'color-linkPrimary',
  message,
  onAchievement,
  render,
  ...restTypographyProps
}) => {
  const { displaySmallRelativeTime } = useTime()
  const { getPath } = useNavigation()
  const { account } = useUserAccount()

  const { t } = useRcTranslation([TranslationNamespace.InboxMessage])

  const args = message.args || []
  const grouped = groupBy(args, it => it.type)

  const extract = (
    type: EntityType,
    grouped: ReturnType<typeof groupBy<ResolvedArg[]>>
  ) => {
    const values = grouped[type]

    return values?.reduce(
      (accumulator: any, value: any, index: number) => {
        Object.keys(value).forEach(key => {
          const v = value[key]
          const p = `${type}-${index}-${key}`
          accumulator[p] = v
        })
        return accumulator
      },
      {} as Record<string, string>
    )
  }

  const getEventCountdown = (date?: Date) => {
    if (!date) return
    const now = moment()
    const then = moment(date)
    const when = now.to(then)

    return now.isBefore(then)
      ? t('inbox-message:starts-in', { when })
      : t('inbox-message:started', { when })
  }
  const achievement = grouped[EntityType.ACHIEVEMENT]?.[0]
  const activity = grouped[EntityType.ACTIVITY]?.[0]
  const bracket = grouped[EntityType.COMPETITIONBRACKET]?.[0]
  const bracketMatch = grouped[EntityType.BRACKETMATCH]?.[0]
  const user = grouped[EntityType.USER]?.[0]
  const team1 = grouped[EntityType.COMPETITIONENTRY]?.[0]
  const competition = grouped[EntityType.COMPETITION]?.[0]
  const community = grouped[EntityType.COMMUNITY]?.[0]
  const formresponse = grouped[EntityType.FORMRESPONSE]?.[0]
  const eventCountdown = getEventCountdown(
    grouped[EntityType.COMPETITIONEVENT]?.[0]?.date
  )
  const rank = Number(message.state?.rank)
    ? t('inbox-message:rank-place', {
        rank: t(`shared.rank-${message.state?.rank}`)
      })
    : t(`shared.unranked`).toLowerCase()
  const originalRank = Number(message.state?.originalRank)
    ? t(`shared.rank-${message.state?.originalRank}`)
    : t(`shared.unranked`).toLowerCase()
  const socialId = message.state?.socialId

  const tOptions = {
    ...extract(EntityType.ACHIEVEMENT, grouped),
    ...extract(EntityType.ACTIVITY, grouped),
    ...extract(EntityType.COMPETITIONBRACKET, grouped),
    ...extract(EntityType.BRACKETMATCH, grouped),
    ...extract(EntityType.COMMUNITY, grouped),
    ...extract(EntityType.USER, grouped),
    ...extract(EntityType.COMPETITIONENTRY, grouped),
    ...extract(EntityType.COMPETITION, grouped),
    ...extract(EntityType.COMPETITIONSECTION, grouped),
    ...extract(EntityType.COMPETITIONEVENT, grouped),
    ...extract(EntityType.DONATION, grouped),
    ...extract(EntityType.FORMRESPONSE, grouped),
    eventCountdown,
    rank,
    originalRank,
    stream: message.state?.name
  }
  const isSelf = user?.id === account?.id

  const adjustedCode = message.code?.replace('competition.entry.', 'team.')

  let link = undefined
  if (adjustedCode === 'user.achieved.created') {
    link = getPath({
      root: RootRoute.Achievement,
      rootId: achievement?.id
    })
  }
  if (socialId) {
    link = getPath({
      root: RootRoute.Chat,
      rootId: socialId
    })
  } else if (activity) {
    link = getPath({
      root: RootRoute.PartyFinder,
      rootId: activity?.id
    })
  } else if (competition && bracketMatch) {
    link = getPath({
      root: RootRoute.Competition,
      rootId: competition?.id,
      subRoute: CompetitionRoute.Match,
      subId: bracketMatch?.id
    })
  } else if (competition && bracket) {
    link = getPath({
      root: RootRoute.Competition,
      rootId: competition?.id,
      subRoute: CompetitionRoute.Bracket,
      subId: bracket?.id
    })
  } else if (
    competition &&
    team1 &&
    adjustedCode !== 'competition.entry.deleted'
  ) {
    link = getPath({
      root: RootRoute.Competition,
      rootId: competition?.id,
      subRoute: CompetitionRoute.Team,
      subId: team1?.id
    })
  } else if (competition) {
    link = getPath({
      root: RootRoute.Competition,
      rootId: competition.id,
      subRoute: CompetitionRoute.Info
    })
  } else if (community) {
    link = getPath({
      root: adjustedCode?.includes('team')
        ? RootRoute.Squad
        : RootRoute.Community,
      rootId: community?.id,
      subRoute:
        adjustedCode === 'community.request.application.created'
          ? CommunityRoute.Settings
          : '',
      subId:
        adjustedCode === 'community.request.application.created'
          ? CommunitySettingsRoute.ApprovalQueue
          : undefined
    })
  } else if (adjustedCode?.startsWith('twitch')) {
    link = getPath({
      root: RootRoute.Root,
      query: `stream=${message.state?.name}`
    })
  } else if (formresponse) {
    link = getPath({ root: RootRoute.FormResponse, rootId: formresponse?.id })
  }

  const renderIcon = (messageCode: string): JSX.Element => {
    const iconMappings: [string, JSX.Element][] = [
      ['competition.match', <RcIcon key={1} icon={['fal', 'balance-scale']} />],
      ['competition.bracket', <RcIcon key={2} icon={['fal', 'swords']} />],
      ['competition', <RcIcon key={3} icon={['fal', 'trophy']} />],
      ['community', <RcIcon key={4} icon={['fal', 'school']} />],
      ['activity', <RcIcon key={5} icon={['fal', 'users-crown']} />],
      ['twitch', <RcIcon key={6} icon={['fab', 'twitch']} />]
    ]

    for (const [prefix, icon] of iconMappings) {
      if (messageCode.startsWith(prefix)) {
        return icon
      }
    }

    return <RcIcon icon={['fad', 'cat-space']} />
  }

  // Keep these comments for i18next-parser dynamic strings
  // t('inbox-message:competition-entry-checkin-created')
  // t('inbox-message:user-achieved-created')
  // t('inbox-message:activity-started')
  // t('inbox-message:activity-start-reminder')
  // t('inbox-message:activity-participant-created')
  // t('inbox-message:activity-participant-application-created')
  // t('inbox-message:activity-participant-invitation-created')
  // t('inbox-message:activity-participant-application-accepted')
  // t('inbox-message:activity-participant-application-declined')
  // t('inbox-message:activity-participant-invitation-accepted')
  // t('inbox-message:activity-participant-invitation-declined')
  // t('inbox-message:bracket-match-arranged')
  // t('inbox-message:competition-entry-created-self')
  // t('inbox-message:competition-entry-created-other')
  // t('inbox-message:competition-entry-updated-description-self')
  // t('inbox-message:competition-entry-updated-description-other')
  // t('inbox-message:competition-event-reminder')
  // t('inbox-message:competition-bracket-assigned')
  // t('inbox-message:competition-bracket-match-completed')
  // t('inbox-message:community-bracket-match-loss')
  // t('inbox-message:community-bracket-match-tied')
  // t('inbox-message:community-bracket-match-won')
  // t('inbox-message:community-request-application-created')
  // t('inbox-message:community-request-application-accepted')
  // t('inbox-message:community-request-invitation-created')
  // t('inbox-message:community-section-changed-description')
  // t('inbox-message:community-member-created-self')
  // t('inbox-message:community-member-created-other')
  // t('inbox-message:community-ticket-created')
  // t('inbox-message:community-ticket-confirm-created')
  // t('inbox-message:competition-ticket-created')
  // t('inbox-message:competition-ticket-confirm-created')
  // t('inbox-message:competition-participant-created-self')
  // t('inbox-message:competition-participant-created-other')
  // t('inbox-message:competition-participant-deleted-self')
  // t('inbox-message:competition-participant-deleted-other')
  // t('inbox-message:competition-section-updated-description')
  // t('inbox-message:member-request-created')
  // t('inbox-message:entry-member-request-accepted')
  // t('inbox-message:entry-member-request-declined')
  // t('inbox-message:member-application-created')
  // t('inbox-message:entry-member-application-accepted')
  // t('inbox-message:entry-member-application-declined')
  // t('inbox-message:member-invitation-created')
  // t('inbox-message:entry-member-invitation-accepted')
  // t('inbox-message:entry-member-invitation-declined')
  // t('inbox-message:entry-deleted')
  // t('inbox-message:entry-member-created-self')
  // t('inbox-message:entry-member-created-other')
  // t('inbox-message:entry-rank-assigned')
  // t('inbox-message:entry-rank-assigned-no-competition')
  // t('inbox-message:entry-rank-assigned-no-entry')
  // t('inbox-message:entry-rank-changed')
  // t('inbox-message:entry-rank-changed-no-competition')
  // t('inbox-message:entry-rank-changed-no-entry')
  // t('inbox-message:competition-match-reported')
  // t('inbox-message:competition-match-conflicted')
  // t('inbox-message:entry-member-removed')
  // t('inbox-message:entry-member-removed-kick')
  // t('inbox-message:competition-donation-created')
  // t('inbox-message:order-gift-received')
  // t('inbox-message:user-bracket-match-tied')
  // t('inbox-message:user-bracket-match-won')
  // t('inbox-message:user-bracket-match-loss')
  // t('inbox-message:form-response-completed')
  // t('inbox-message:form-assigned-section-completed')
  // t('inbox-message:squad-request-invitation-created')
  // t('inbox-message:squad-request-application-created')
  // t('inbox-message:twitch-stream-live')
  // t('inbox-message:match-escalation-created')
  // t('inbox-message:match-scheduler-created')

  const content = (
    <Grid
      container
      direction='row'
      alignItems='center'
      spacing={3}
      justifyContent='space-between'
    >
      <Grid item>
        <RcAvatar
          variant={user?.image ? 'circular' : 'rounded'}
          src={
            user?.image ||
            team1?.image ||
            competition?.image ||
            activity?.image ||
            community?.image
          }
          alt={
            user?.name || team1?.name || competition?.name || community?.name
          }
          sx={{
            background: message?.code?.startsWith('twitch')
              ? TWITCH_PURPLE
              : undefined
          }}
        >
          {renderIcon(adjustedCode!)}
        </RcAvatar>
      </Grid>
      <Grid item xs>
        <Typography
          display='inline'
          variant='body1'
          color='text.primary'
          mr={2}
          {...restTypographyProps}
        >
          <RcTrans
            i18nKey={
              (
                {
                  'user.achieved.created':
                    'inbox-message:user-achieved-created',
                  'competition.checkin.created':
                    'inbox-message:competition-entry-checkin-created',
                  'activity.started': 'inbox-message:activity-started',
                  'activity.start-reminder':
                    'inbox-message:activity-start-reminder',
                  'rally.start-reminder':
                    'inbox-message:activity-start-reminder',
                  'activity.participant.created':
                    'inbox-message:activity-participant-created',
                  'activity.participant.application.created':
                    'inbox-message:activity-participant-application-created',
                  'activity.participant.invitation.created':
                    'inbox-message:activity-participant-invitation-created',
                  'activity.participant.application.accepted':
                    'inbox-message:activity-participant-application-accepted',
                  'activity.participant.application.declined':
                    'inbox-message:activity-participant-application-declined',
                  'activity.participant.invitation.accepted':
                    'inbox-message:activity-participant-invitation-accepted',
                  'activity.participant.invitation.declined':
                    'inbox-message:activity-participant-invitation-declined',
                  'bracket.match.arranged':
                    'inbox-message:bracket-match-arranged',
                  'team.created': isSelf
                    ? 'inbox-message:competition-entry-created-self'
                    : 'inbox-message:competition-entry-created-other',
                  'team.updated.description': isSelf
                    ? 'inbox-message:competition-entry-updated-description-self'
                    : 'inbox-message:competition-entry-updated-description-other',
                  'competition.event.reminder':
                    'inbox-message:competition-event-reminder',
                  'competition.bracket.assigned':
                    'inbox-message:competition-bracket-assigned',
                  'competition.bracket.match.completed':
                    'inbox-message:competition-bracket-match-completed',
                  'community.bracket.match.loss':
                    'inbox-message:community-bracket-match-loss',
                  'community.bracket.match.tied':
                    'inbox-message:community-bracket-match-tied',
                  'community.bracket.match.won':
                    'inbox-message:community-bracket-match-won',
                  'community.request.application.created':
                    'inbox-message:community-request-application-created',
                  'community.request.application.accepted':
                    'inbox-message:community-request-application-accepted',
                  'community.request.invitation.created':
                    'inbox-message:community-request-invitation-created',
                  'community.section.changed.description':
                    'inbox-message:community-section-changed-description',
                  'community.member.created': isSelf
                    ? 'inbox-message:community-member-created-self'
                    : 'inbox-message:community-member-created-other',
                  'community.ticket.created':
                    'inbox-message:community-ticket-created',
                  'community.ticket.confirm-created':
                    'inbox-message:community-ticket-confirm-created',
                  'competition.ticket.created':
                    'inbox-message:competition-ticket-created',
                  'competition.ticket.confirm-created':
                    'inbox-message:competition-ticket-confirm-created',
                  'competition.participant.created': isSelf
                    ? 'inbox-message:competition-participant-created-self'
                    : 'inbox-message:competition-participant-created-other',
                  'competition.participant.deleted': isSelf
                    ? 'inbox-message:competition-participant-deleted-self'
                    : 'inbox-message:competition-participant-deleted-other',
                  'competition.section.updated.description':
                    'inbox-message:competition-section-updated-description',
                  'team.member.request.created':
                    'inbox-message:member-request-created',
                  'team.member.request.accepted':
                    'inbox-message:entry-member-request-accepted',
                  'team.member.request.declined':
                    'inbox-message:entry-member-request-declined',
                  'team.member.application.created':
                    'inbox-message:member-application-created',
                  'team.member.application.accepted':
                    'inbox-message:entry-member-application-accepted',
                  'team.member.application.declined':
                    'inbox-message:entry-member-application-declined',
                  'team.member.invitation.created':
                    'inbox-message:member-invitation-created',
                  'team.member.invitation.accepted':
                    'inbox-message:entry-member-invitation-accepted',
                  'team.member.invitation.declined':
                    'inbox-message:entry-member-invitation-declined',
                  'team.deleted': 'inbox-message:entry-deleted',
                  'team.member.created': isSelf
                    ? 'inbox-message:entry-member-created-self'
                    : 'inbox-message:entry-member-created-other',
                  'team.rank.assigned':
                    competition && team1
                      ? 'inbox-message:entry-rank-assigned'
                      : team1
                      ? 'inbox-message:entry-rank-assigned-no-competition'
                      : 'inbox-message:entry-rank-assigned-no-entry',
                  'team.rank.changed':
                    competition && team1
                      ? 'inbox-message:entry-rank-changed'
                      : team1
                      ? 'inbox-message:entry-rank-changed-no-competition'
                      : 'inbox-message:entry-rank-changed-no-entry',
                  'team.member.removed': 'inbox-message:entry-member-removed',
                  'team.member.removed.kick':
                    'inbox-message:entry-member-removed-kick',
                  'competition.match.reported':
                    'inbox-message:competition-match-reported',
                  'competition.match.conflicted':
                    'inbox-message:competition-match-conflicted',
                  'competition.donation.created':
                    'inbox-message:competition-donation-created',
                  'order.gift.received': 'inbox-message:order-gift-received',
                  'user.bracket.match.tied':
                    'inbox-message:user-bracket-match-tied',
                  'user.bracket.match.won':
                    'inbox-message:user-bracket-match-won',
                  'user.bracket.match.loss':
                    'inbox-message:user-bracket-match-loss',
                  'form.response.completed':
                    'inbox-message:form-response-completed',
                  'form.assigned-section.completed':
                    'inbox-message:form-assigned-section-completed',
                  'team.request.invitation.created':
                    'inbox-message:squad-request-invitation-created',
                  'team.request.application.created':
                    'inbox-message:squad-request-application-created',
                  'twitch.stream.live': 'inbox-message:twitch-stream-live',
                  'match.escalation.created':
                    'inbox-message:match-escalation-created',
                  'match.scheduler.created':
                    'inbox-message:match-scheduler-created'
                } as any
              )[adjustedCode!] ||
              adjustedCode ||
              'unknown'
            }
            tOptions={tOptions}
          >
            <strong className={emphasisColor}>{adjustedCode}</strong>
            <strong className={emphasisColor}>1</strong>
          </RcTrans>
        </Typography>
        <Typography
          display='inline'
          variant='body2'
          color='text.secondary'
          noWrap
        >
          {displaySmallRelativeTime(message.dateCreated)}
        </Typography>
      </Grid>
    </Grid>
  )
  return achievement ? (
    <Box
      onClick={() => {
        onAchievement(achievement, community)
      }}
    >
      {render(content)}
    </Box>
  ) : link ? (
    <NavigationLink to={link} underline='none'>
      {render(content)}
    </NavigationLink>
  ) : (
    render(content)
  )
}
