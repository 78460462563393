import Box from '@mui/material/Box'
import Stack from '@mui/material/Stack'
import { BracketKind } from '@rallycry/api-suite-typescript/dist/models/BracketKind'
import { CompetitionBracketResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionBracketResource'
import { find, maxBy, minBy, range } from 'lodash-es'
import { useEffect, useState } from 'react'
import Paper from '@mui/material/Paper'
import { BracketChallongeZoom } from './BracketChallongeZoom'
import { BracketChallongeRepresenting } from './BracketChallongeRepresenting'
import { BracketChallongeSeed } from './BracketChallongeSeed'
import { useFeatures } from '@/components/providers/site/FeatureProvider'
import { expander } from '@/core/expand'
import { CompetitionRoute, RootRoute } from '@/core/route-keys'
import { useBracketMatchesByRound } from '@/entity/bracket-match/useBracketMatchesByRound'
import { useBracket } from '@/entity/bracket/useBracket'
import { useCompetitionTeamDocument } from '@/entity/competition-team/useCompetitionTeamDocument'
import { useCompetition } from '@/entity/competition/useCompetition'
import { useOrganization } from '@/entity/organization/useOrganization'
import { useChallonge } from '@/core/hooks/useChallonge'
import { useIsXs } from '@/core/hooks/useMediaQueries'
import { useNavigation } from '@/core/hooks/useNavigation'
import { useLocalStorage } from '@/core/hooks/useLocalStorage'
import { BracketChallongeStandings } from './BracketChallongeStandings'

export const BracketChallonge = ({ lastUpdated }: { lastUpdated?: Date }) => {
  const { organization } = useOrganization()
  const { competition, isSoloCompetition } = useCompetition()
  const { bracket, bracketSettings } = useBracket()
  const { matches } = useBracketMatchesByRound({ lastUpdated })
  const { teams } = useCompetitionTeamDocument({
    lastUpdated,
    pageSize: 2000,
    request: {
      competitionId: competition?.id ? [competition?.id!] : undefined,
      brackets: bracket?.id ? [bracket?.id] : undefined
    }
  })
  const { cfgChallongeThemeDark, featHideCommunity, imgCommunityAvatar } =
    useFeatures()
  const {
    ref,
    url,
    bracketHeight,
    setBracketSettings,
    setConfiguration,
    setIntegrationData,
    setZoomScale
  } = useChallonge()

  const { navTo } = useNavigation()

  const isMobile = useIsXs()

  const [state, setState] = useState({
    fullScreen: false,
    zoomScale: 1,
    overrideSettings: {
      tournamentOptionOverrides: {
        hideIdentifiers: true,
        hideSeeds: true
      }
    }
  })

  const [showRepresenting] = useLocalStorage({
    key: 'user-bracket-representing',
    defaultValue: false
  })

  const isGroupBracket =
    bracketSettings?.kind === BracketKind.CUSTOM ||
    bracketSettings?.kind === BracketKind.SWISS ||
    bracketSettings?.kind === BracketKind.ROUNDROBIN

  const isSingleRestrictedCommunity =
    competition?._restrictToCommunities?.length === 1

  const challongeTopFilter =
    bracketSettings?.kind == BracketKind.DOUBLEELIMINATION && teams.length >= 16

  const tableHead = isMobile ? 172 : 62
  const tableRow = 38

  // Handle user clicking challonge match
  const onMatchClicked = (externalId: string) => {
    const match = matches.find(it => it.externalId === `${externalId}`)
    if (match) {
      const bracket = expander<CompetitionBracketResource>(
        match,
        'competitionBracket'
      )
      navTo({
        root: RootRoute.Competition,
        rootId: bracket?.competition?.id,
        subRoute: CompetitionRoute.Match,
        subId: match?.id
      })
    }
  }

  // Handle zoom
  const handleZoom = () => {
    setState(s => ({ ...s, zoomScale: s.zoomScale + 0.2 }))
    setZoomScale(state.zoomScale + 0.2)
  }
  const handleZoomOut = () => {
    setState(s => ({ ...s, zoomScale: s.zoomScale - 0.2 }))
    setZoomScale(state.zoomScale - 0.2)
  }

  // Handle seed toggle
  const handleToggleSeed = () => {
    setState(s => ({
      ...s,
      overrideSettings: {
        tournamentOptionOverrides: {
          ...s.overrideSettings.tournamentOptionOverrides,
          hideSeeds: !s.overrideSettings.tournamentOptionOverrides.hideSeeds
        }
      }
    }))
  }
  setBracketSettings(state.overrideSettings)

  // Update integration data
  useEffect(() => {
    if (!teams) return
    const data = teams?.reduce(
      (acc, val) => {
        // Logic mirrored from useCompetitionTeamImage
        const community =
          val?.representing?.additionalOrganizations?.find(
            it => it.organization?.id === organization?.id
          ) || val?.representing
        const defaultAvatarSrc = imgCommunityAvatar as string
        const communityImage =
          featHideCommunity || isSingleRestrictedCommunity
            ? ''
            : community?.image
        const teamImage = isSoloCompetition
          ? val?.leader?.image || communityImage || defaultAvatarSrc
          : communityImage || val?.leader?.image || defaultAvatarSrc

        acc[val.id!] = {
          display_name:
            showRepresenting &&
            !featHideCommunity &&
            !isSingleRestrictedCommunity
              ? val.representing?.name
              : val.abbreviation || val.alternateName,
          portrait_url: teamImage
        }
        return acc
      },
      {} as Record<number, any>
    )
    setIntegrationData(data)
  }, [
    featHideCommunity,
    imgCommunityAvatar,
    isSingleRestrictedCommunity,
    isSoloCompetition,
    organization?.id,
    setIntegrationData,
    showRepresenting,
    teams
  ])

  // Update challonge configuration
  useEffect(
    () => {
      if (!bracket || !competition || !matches) return
      const themeId = cfgChallongeThemeDark as string

      const minRound = minBy(matches, it => it.round)?.round!
      const maxRound = maxBy(matches, it => it.round)?.round!
      const allRounds = range(minRound, maxRound + 1)
      const filterRounds = allRounds.filter(
        it =>
          !find(
            bracket?._expanded?.bracketRound,
            round => round.filtered && round.number === it
          )
      )

      setConfiguration({
        bracketId: bracket.externalKey!,
        filteredRounds: filterRounds,
        onMatchClicked,
        overrideSettings: state.overrideSettings,
        themeId
      })
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [
      bracket,
      cfgChallongeThemeDark,
      competition,
      isGroupBracket,
      matches,
      // onMatchClicked,
      setConfiguration
    ]
  )

  return (
    <>
      {isGroupBracket && bracket?.externalKey ? (
        <BracketChallongeStandings
          bracketExternalKey={bracket.externalKey}
          height={teams.length * tableRow + tableHead}
        />
      ) : null}
      <Box
        sx={{
          position: 'relative',
          overflow: 'hidden',
          borderRadius: 1,
          height: bracketHeight,
          pl: 4,
          // Double Elim > 16 adds built in padding otherwise it needs it
          pt: challongeTopFilter ? 0 : 4,
          transition: 'height 0.3s'
        }}
      >
        <Paper
          sx={{
            position: 'absolute',
            right: 0,
            top: 0,
            padding: 2,
            minWidth: 160,
            minHeight: 60
          }}
        >
          {/* Bracket Controls if it's not a group bracket */}
          {isGroupBracket ? null : (
            <Stack direction='row-reverse' spacing={1} pr={21}>
              <BracketChallongeZoom
                handleZoom={handleZoom}
                handleZoomOut={handleZoomOut}
              />
              <BracketChallongeSeed
                hideSeed={
                  state.overrideSettings.tournamentOptionOverrides.hideSeeds
                }
                handleToggle={handleToggleSeed}
              />
              {isSingleRestrictedCommunity || featHideCommunity ? null : (
                <BracketChallongeRepresenting
                  isSoloCompetition={isSoloCompetition}
                />
              )}
            </Stack>
          )}
        </Paper>

        {/* Bracket */}
        <iframe
          frameBorder='0'
          height='100%'
          ref={ref}
          src={url.href}
          title='bracket'
          width='100%'
        />
      </Box>
    </>
  )
}
