import { RcTrans } from '@/components/atoms/RcTrans'
import { UserAvatar } from '@/components/organisms/avatar/UserAvatar'
import { UserNetwork } from '@/components/organisms/user/UserNetwork'
import { useCompetitionTeamContactAccounts } from '@/entity/competition-team/useCompetitionTeamContactAccounts'
import { TeamRank } from '@/entity/competition-team/useCompetitionTeamMembers'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import Stack from '@mui/material/Stack'
import { UserResource } from '@rallycry/api-suite-typescript'

interface TeamRosterUserProps {
  user?: UserResource & { rank?: TeamRank }
  hideChip?: boolean
}

export const TeamRosterUser = ({ user, hideChip }: TeamRosterUserProps) => {
  if (!user) return null
  const roleText = getRosterRoleText(user.rank)

  return (
    <UserAvatar
      user={user}
      chipProps={roleText && !hideChip ? { label: roleText } : undefined}
      badgeIcon={getRosterBadgeIcon(user?.rank)}
      badgeTooltip={roleText}
      extra={<UserCompetitionContacts userId={user?.id} />}
    />
  )
}

export const getRosterBadgeIcon = (rank?: TeamRank) =>
  rank === TeamRank.HeadCoach || rank === TeamRank.HeadCaptain
    ? (['fal', 'web-awesome'] as IconProp)
    : rank === TeamRank.AssistantCoach || rank === TeamRank.AssistantCaptain
    ? (['fal', 'pennant'] as IconProp)
    : undefined

export const getRosterRoleText = (rank?: TeamRank) =>
  rank === TeamRank.HeadCoach ? (
    <RcTrans i18nKey='competition:roster.head-coach' />
  ) : rank === TeamRank.HeadCaptain ? (
    <RcTrans i18nKey='competition:roster.head-captain' />
  ) : rank === TeamRank.AssistantCoach ? (
    <RcTrans i18nKey='competition:roster.assistant-coach' />
  ) : rank === TeamRank.AssistantCaptain ? (
    <RcTrans i18nKey='competition:roster.assistant-captain' />
  ) : undefined

export const UserCompetitionContacts = ({ userId }: { userId?: number }) => {
  const { contacts } = useCompetitionTeamContactAccounts()

  return (
    <Stack direction='row' spacing={1}>
      {contacts
        ?.filter(it => it.user?.id === userId)
        ?.map((account, index) => (
          <UserNetwork key={index} account={account} condensed disableDelete />
        ))}
    </Stack>
  )
}
