import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import {
  BracketMatchResource,
  CompetitionBracketResource,
  EmbeddedEvent
} from '@rallycry/api-suite-typescript'
import { CompetitionResource } from '@rallycry/api-suite-typescript/dist/models/CompetitionResource'
import { MatchStatusChip } from '../MatchStatusChip'
import { CompetitionRoute, RootRoute } from '@/core/route-keys'
import { useTime } from '@/core/hooks/useTime'
import { useNavigation } from '@/core/hooks/useNavigation'
import { NavigationLink } from '@/components/organisms/navigation/NavigationLink'
import { PreventParentClick } from '@/components/molecules/interactive/PreventParentClick'
import { EllipsisBox } from '@/components/molecules/text/EllipsisBox'
import { useIsMobile } from '@/core/hooks/useMediaQueries'

interface MatchRoundInfoProps {
  bracket?: CompetitionBracketResource
  match?: BracketMatchResource
  competition?: CompetitionResource
  event?: Omit<EmbeddedEvent, 'timeZone'>
  size?: 'body1' | 'body2'
  vertical?: boolean
}

export const MatchRoundInfo = ({
  bracket,
  match,
  competition,
  event,
  size = 'body2',
  vertical
}: MatchRoundInfoProps) => {
  const { getPath } = useNavigation()
  const { displayEventDateTime } = useTime()
  const isMobile = useIsMobile()

  return (
    <Stack
      direction={vertical ? 'column' : 'row'}
      alignItems='center'
      spacing={vertical ? 0 : 2}
      mt={vertical ? 1 : 0}
    >
      <PreventParentClick>
        <NavigationLink
          to={getPath({
            root: RootRoute.Competition,
            rootId: competition?.id,
            subRoute: CompetitionRoute.Bracket,
            subId: bracket?.id
          })}
        >
          <EllipsisBox lineClamp={1} px={isMobile ? 0 : 8}>
            <Typography variant={size} color='text.primary'>
              {bracket?.disambiguatingDescription
                ? bracket.disambiguatingDescription + ' - '
                : null}
              {bracket?.name}
            </Typography>
          </EllipsisBox>
        </NavigationLink>
      </PreventParentClick>
      {event ? (
        <PreventParentClick>
          <Typography variant={'body2'} color='text.secondary'>
            {displayEventDateTime(event.startDate!)}
          </Typography>
        </PreventParentClick>
      ) : null}

      <MatchStatusChip match={match} sx={{ mt: 1, zoom: 0.9 }} />
    </Stack>
  )
}
