import TableCell from '@mui/material/TableCell'
import TableRow from '@mui/material/TableRow'
import { TableRowProps } from '@mui/material/TableRow'
import { UserResource } from '@rallycry/api-suite-typescript/dist/models/UserResource'
import { RcTrans } from '@/components/atoms/RcTrans'
import { RcButton } from '@/components/molecules/interactive/RcButton'
import { UserAvatar } from '@/components/organisms/avatar/UserAvatar'
import { useCompetitionTeam } from '@/entity/competition-team/useCompetitionTeam'
import { useCompetitionTeamMembers } from '@/entity/competition-team/useCompetitionTeamMembers'

export interface SearchDependentsRowProps extends TableRowProps {
  user: UserResource
  isInRoster: boolean
}

export const SearchDependentsRow: React.FC<SearchDependentsRowProps> = ({
  user,
  isInRoster,
  ...rest
}) => {
  const { addMember } = useCompetitionTeamMembers()

  return (
    <TableRow {...rest}>
      <TableCell>
        <UserAvatar user={user} />
      </TableCell>
      <TableCell align='right' sx={{ justifyContent: 'flex-end' }}>
        <RcButton disabled={isInRoster} onClick={() => addMember(user?.id!)}>
          {isInRoster ? (
            <RcTrans i18nKey='shared.added' />
          ) : (
            <RcTrans i18nKey='shared.add' />
          )}
        </RcButton>
      </TableCell>
    </TableRow>
  )
}
