import { UserDocument } from '@rallycry/api-suite-typescript/dist/models/UserDocument'
import { useCallback } from 'react'
import { RcTrans } from '@/components/atoms/RcTrans'
import {
  ActionMenu,
  ActionMenuOption
} from '@/components/molecules/interactive/ActionMenu'
import { RcIconButton } from '@/components/molecules/interactive/RcIconButton'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { PageItemContent } from '@/components/organisms/page/PageItemContent'
import { UserSearch } from '@/components/organisms/user/UserSearch'
import { useCompetitionTeamInvitations } from '@/entity/competition-team/useCompetitionTeamInvitations'
import { useCompetitionTeamMembers } from '@/entity/competition-team/useCompetitionTeamMembers'
import { useCompetition } from '@/entity/competition/useCompetition'

export const SearchUsers = () => {
  const { settings, isCompetitionModerator } = useCompetition()
  const { roster, addMember, promoteMember } = useCompetitionTeamMembers()
  const { invite, invitations } = useCompetitionTeamInvitations()

  const maxInvites = settings?.teamSizeMax! * 2
  const availableInvites = maxInvites - invitations.length

  const actions = useCallback(
    (it: UserDocument) => {
      const invitation = invitations?.find(that => that.recipient?.id === it.id)
      const isCoachOrMember = roster?.find(that => that?.id === it.id)

      const options: ActionMenuOption[] = []
      if (availableInvites > 0) {
        options.push({
          key: 'invite',
          display: (
            <RcTrans i18nKey='competition:invite-flow.find-users.send-invitation' />
          ),
          action: async () => invite(it.id!)
        })
      }

      if (invitation) {
        options.push({
          key: 'already-invited',
          display: (
            <RcTrans i18nKey='competition:invite-flow.find-users.invitation-pending' />
          ),
          disabled: true
        })
      }

      if (isCompetitionModerator && !isCoachOrMember) {
        options.push({
          key: 'force-coach',
          display: (
            <RcTrans i18nKey='competition:invite-flow.find-users.force-add-as-coach' />
          ),
          action: () => promoteMember(it.id!)
        })
        options.push({
          key: 'force-add',
          display: (
            <RcTrans i18nKey='competition:invite-flow.find-users.force-add-as-member' />
          ),
          action: () => addMember(it.id!)
        })
      }

      return isCoachOrMember ? (
        <RcIconButton
          icon={['fal', 'check']}
          disabled
          TooltipProps={{
            title: (
              <RcTrans i18nKey='competition:invite-flow.find-users.already-member' />
            )
          }}
        />
      ) : (
        <ActionMenu options={options} />
      )
    },
    [
      invitations,
      availableInvites,
      addMember,
      promoteMember,
      invite,
      isCompetitionModerator,
      roster
    ]
  )

  // t('competition:invite-flow.find-users.remaining-invites', { count })
  return (
    <>
      <UserSearch actions={actions} />
      <ModalConfiguration
        title={<RcTrans i18nKey='competition:team.user-search-header' />}
        subtitle={
          <PageItemContent pt={0}>
            {availableInvites > 0 ? (
              <RcTrans
                i18nKey='competition:invite-flow.find-users.remaining-invites'
                tOptions={{
                  count: availableInvites
                }}
              />
            ) : (
              <RcTrans i18nKey='competition:invite-flow.find-users.no-remaining-invites' />
            )}
          </PageItemContent>
        }
      />
    </>
  )
}
