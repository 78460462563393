import { CompetitionEntryNoteControllerApi } from '@rallycry/api-suite-typescript'
import { EntityOptions, useReadEntity } from '../useEntity'
import { useCompetitionTeam } from './useCompetitionTeam'
import { useController } from '@/core/hooks/useSWRApi'

export const useCompetitionTeamNote = (options?: EntityOptions) => {
  const {
    team,
    read: { mutate }
  } = useCompetitionTeam()
  const { ctrl } = useController(CompetitionEntryNoteControllerApi)

  const entity = useReadEntity({
    key: `useCompetitionTeamNote`,
    ...options,
    idOrKey: team?.note?.id,
    read: req => ctrl().readCompetitionEntryNote(req),
    suspense: false
  })

  const save = async (note: string) => {
    await ctrl().saveCompetitionEntryNote({
      id: team?.id!,
      CompetitionEntryNoteCommand: {
        value: note
      }
    })
    await entity.read.mutate()
    await mutate()
  }

  const remove = async () => {
    if (!entity?.read?.data?.id) return
    await ctrl().removeCompetitionEntryNote({
      id: entity?.read?.data?.id
    })
    await entity.read.mutate(undefined, { revalidate: false })
    await mutate()
  }

  const hasNote = !!team?.note?.id
  return {
    ...entity,
    hasNote,
    note: hasNote ? entity.read.data : undefined,
    save,
    remove
  }
}
