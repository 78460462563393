import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Stack from '@mui/material/Stack'
import Typography from '@mui/material/Typography'
import { some } from 'lodash-es'
import dynamic from 'next/dynamic'
import {
  ComponentDynamicAvatars,
  ComponentGenericMiniBox,
  Maybe
} from '../../../../../models/types'
import { ActionLink } from '../../Landing/components/ActionLink'
import { useIsMobile } from '@/core/hooks/useMediaQueries'
import { ImageSize, useCMSFile } from '@/core/hooks/useCMSFile'
import { DiscoverCard } from '@/components/organisms/card/DiscoverCard'
import { CardsSection } from '@/components/organisms/card/CardsSection'
import { RcAvatar } from '@/components/molecules/text/RcAvatar'
import { MarkdownDisplay } from '@/components/molecules/input/MarkdownDisplay'

const InfiniteAvatars = dynamic(
  async () => (await import('./InfiniteAvatars')).InfiniteAvatars,
  { ssr: false, loading: () => <></> }
)

export const Avatars = ({
  isFlipped,
  title,
  subtitle,
  description,
  actions,
  variant,
  infinite,
  content
}: ComponentDynamicAvatars & {
  isFlipped: boolean
}) => {
  const isMobile = useIsMobile()

  const avatarsOnly = !description && !some(actions)

  const direction =
    isMobile || avatarsOnly ? 'column' : isFlipped ? 'row-reverse' : 'row'

  return (
    <Stack
      spacing={isMobile || avatarsOnly ? 5 : 20}
      direction={direction}
      justifyContent='space-between'
      sx={{
        maxWidth: '100dvw',
        marginX: {
          xs: infinite ? -5 : 0,
          sm: infinite ? -8 : 0
        }
      }}
    >
      {/* Column 1 */}
      {avatarsOnly ? null : (
        <Stack
          direction='column'
          spacing={4}
          width={isMobile || avatarsOnly ? '100%' : '50%'}
        >
          {title ? <Typography variant='h2'>{title}</Typography> : null}
          {subtitle ? (
            <Typography className='alt' variant='subtitle1'>
              {subtitle}
            </Typography>
          ) : null}
          {description ? <MarkdownDisplay markdown={description} /> : null}
          <Stack direction='row' spacing={3} justifyContent={'left'}>
            {actions?.map(it => <ActionLink key={it?.path} action={it!} />)}
          </Stack>
        </Stack>
      )}
      {/* Column 2 */}
      <Box width={isMobile || avatarsOnly ? '100%' : '50%'}>
        {avatarsOnly ? (
          <Typography variant='h2' textAlign='center' mb={15}>
            {title}
          </Typography>
        ) : null}

        {infinite ? (
          <InfiniteAvatars content={content} />
        ) : isMobile || avatarsOnly ? (
          <CardsSection
            cardItems={content!}
            render={cardItem =>
              variant === 'card' ? (
                <AvatarDisplaySquare key={cardItem?.id} avatar={cardItem} />
              ) : (
                <AvatarDisplayCircle key={cardItem?.id} avatar={cardItem} />
              )
            }
            interval={3}
          />
        ) : (
          <Grid container direction='row' spacing={6}>
            {content?.map(it => (
              <Grid key={it?.id} item xs={4} sm={avatarsOnly ? 2 : 4}>
                <AvatarDisplayCircle avatar={it} />
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </Stack>
  )
}

export const AvatarDisplaySquare = ({
  avatar
}: {
  avatar: Maybe<ComponentGenericMiniBox>
}) => {
  const { getImagePath } = useCMSFile()
  return (
    <Box width={290}>
      <DiscoverCard
        navigation={avatar?.path || ''}
        headerImage={getImagePath(avatar?.images?.data?.[0], ImageSize.Small)}
        title={avatar?.title}
        primaryChips={[{ label: avatar?.description }]}
      />
    </Box>
  )
}

export const AvatarDisplayCircle = ({
  avatar
}: {
  avatar: Maybe<ComponentGenericMiniBox>
}) => {
  const { getImagePath } = useCMSFile()
  return (
    <Stack direction='column' spacing={1} alignItems='center'>
      <Box sx={{ width: '90%', aspectRatio: '1' }}>
        <RcAvatar
          src={getImagePath(avatar?.images?.data?.[0], ImageSize.Small)}
          sx={{ width: 280, height: 280, aspectRatio: '1' }}
        />
      </Box>
      {avatar?.title ? (
        <Typography variant='h4' textAlign='center'>
          {avatar?.title}
        </Typography>
      ) : null}
      {avatar?.subtitle ? (
        <Typography variant='body1' textAlign='center'>
          {avatar?.subtitle}
        </Typography>
      ) : null}
    </Stack>
  )
}
