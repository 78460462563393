import { FormikHelpers, Formik, Form } from 'formik'
import { TextField } from 'formik-mui'
import Typography from '@mui/material/Typography'
import { RcTrans } from '@/components/atoms/RcTrans'
import { AcceptableSubmit } from '@/components/molecules/input/AcceptableSubmit'
import { MarkdownFormikField } from '@/components/molecules/input/MarkdownFormikField'
import { ConfirmingButton } from '@/components/molecules/interactive/ConfirmingButton'
import { LabeledField } from '@/components/organisms/form/LabeledField'
import { ModalConfiguration } from '@/components/organisms/modal/ModalConfiguration'
import { useApiError } from '@/core/hooks/useApiError'
import { useCompetitionTeamNote } from '@/entity/competition-team/useCompetitionTeamNote'

export const TeamNoteForm = ({ onComplete }: { onComplete: () => void }) => {
  const { handle } = useApiError()
  const { hasNote, note, save, remove } = useCompetitionTeamNote()

  const handleSubmit = async (
    values: { value: string },
    helpers: FormikHelpers<{ value: string }>
  ) => {
    try {
      if (values.value) {
        await save(values.value)
      } else {
        await remove()
      }
      onComplete()
    } catch (e) {
      handle(e, { values, helpers })
    }
  }

  return (
    <Formik
      initialValues={{
        value: note?.value || ''
      }}
      onSubmit={handleSubmit}
    >
      {() => (
        <Form id='team-note-form'>
          <MarkdownFormikField name='value' />

          <ModalConfiguration
            direction='row'
            title={<RcTrans i18nKey='competition:moderator-note' />}
            subtitle={
              <Typography textAlign='center' color='text.secondary'>
                <RcTrans i18nKey='competition:moderator-visibilty' />
              </Typography>
            }
          >
            <ConfirmingButton
              onClick={async () => {
                await remove()
                onComplete()
              }}
              variant='outlined'
              message={<RcTrans i18nKey='shared.confirmation-message' />}
              disabled={!hasNote}
            >
              <RcTrans i18nKey='shared.delete' />
            </ConfirmingButton>
            <AcceptableSubmit />
          </ModalConfiguration>
        </Form>
      )}
    </Formik>
  )
}
